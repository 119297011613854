import { useEffect, useState } from "react"
import { useGetAllServicesQuery } from "../../redux/apiSlices/Service"
import dayjs, { Dayjs } from "dayjs";
import { IServiceDTO } from "../../Interfaces/Service";
import { useUser } from "../../Contexts/UserContext/UserContext";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

type ServiceStats = {
    currentWeekDuration: number,
    currentMonthDuration: number,
    givenTimePeriodDuration: number,

}

export const useGetServiceStats = (userId?: number, startDate?: Dayjs, endDate?: Dayjs) => {
    let userIdToUse: number;
    const { user } = useUser();

    if (userId === undefined) userIdToUse = user?.userId!; else userIdToUse = userId;

    const { data: services } = useGetAllServicesQuery();
    const [serviceStats, setServiceStats] = useState<ServiceStats>({
        currentWeekDuration: 0,
        currentMonthDuration: 0,
        givenTimePeriodDuration: 0,
    });

    useEffect(() => {
        calculateServiceStats(setServiceStats, userIdToUse, services, startDate, endDate);
    }, [services])

    //DEBUG
    useEffect(() => {
        console.log(serviceStats)
    }, [serviceStats])
    return {
        serviceStats
    }
}

function calculateServiceStats(setServiceStats: React.Dispatch<React.SetStateAction<ServiceStats>>, userId: number, services: IServiceDTO[] | undefined, startDate?: Dayjs, endDate?: Dayjs) {

    if (!services || services.length === 0) return;

    let currentWeekDuration = 0;
    let currentMonthDuration = 0;
    let givenTimePeriodDuration = 0;

    const startOfWeek = dayjs().startOf('week');
    const endOfWeek = dayjs().endOf('week');

    const startOfMonth = dayjs().startOf('month');
    const endOfMonth = dayjs().endOf('month');

    services.forEach((service) => {
        let userEmployeeDatas = service.employeeDatas.filter((data) => data.userId === userId);

        if (!userEmployeeDatas || userEmployeeDatas.length === 0) {
            return;
        };

        userEmployeeDatas.forEach((employeeData) => {
            const employeeDataStart = dayjs(employeeData.from);
            const employeeDataEnd = dayjs(employeeData.to);

            const duration = employeeDataEnd.diff(employeeDataStart, 'hour', true);

            if (employeeDataStart.isSameOrAfter(startOfWeek) && employeeDataEnd.isSameOrBefore(endOfWeek)) {
                currentWeekDuration += duration;
            }

            if (employeeDataStart.isSameOrAfter(startOfMonth) && employeeDataEnd.isSameOrBefore(endOfMonth)) {
                currentMonthDuration += duration;
            }

            if (!startDate || !endDate) return;

            if (employeeDataStart.isSameOrAfter(startDate) && employeeDataEnd.isSameOrBefore(endDate)) {
                givenTimePeriodDuration += duration;
            }
        })
    });

    setServiceStats({
        currentWeekDuration,
        currentMonthDuration,
        givenTimePeriodDuration,
    });
}
