import { CircularProgress } from '@mui/material'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useCompanyData } from '../../../Contexts/CompanyDataContext/CompanyDataProvider'
import { IIntervallDTO, IIntervallPosition, useIntervalls } from '../../../Contexts/IntervallContext/IntervallProvider'
import { IProductDTO } from '../../../Contexts/ProductContext/ProductProvider'
import useAuthConnection from '../../../Hooks/useAuthConnection'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import EditCreateIntervallPositionForm from '../../Forms/EditCreateIntervallPositionForm'


export type dialog = {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    onClose?: () => void
    object?: IIntervallPosition | undefined
    intervall: IIntervallDTO | undefined
    mode: "create" | "edit" | "createNewIntervall"
    setIntervall?: Dispatch<SetStateAction<IIntervallDTO>>
    index?: number
}

export const validateIntervallPositionForm = (intervallPosition: IIntervallPosition, setError: Dispatch<SetStateAction<any>>) => {
    let valid = true;

    if (intervallPosition.title === undefined || intervallPosition.title === "") {
        setError((old: any) => ({ ...old, titleError: true }))
        valid = false;
    } else {
        setError((old: any) => ({ ...old, titleError: false }))
    }

    if (intervallPosition.hasDiscount && !intervallPosition.discount) {
        setError((old: any) => ({ ...old, discountError: true }))
        valid = false;
    } else {
        setError((old: any) => ({ ...old, discountError: false }))
    }

    if (intervallPosition.netto === undefined || intervallPosition.netto === null) {
        setError((old: any) => ({ ...old, nettoError: true }))
        valid = false;
    } else {
        setError((old: any) => ({ ...old, nettoError: false }))
    }

    if (!intervallPosition.tax) {
        setError((old: any) => ({ ...old, taxError: true }))
        valid = false;
    } else {
        setError((old: any) => ({ ...old, taxError: false }))
    }

    if (!intervallPosition.quantity) {
        setError((old: any) => ({ ...old, quantityError: true }))
        valid = false;
    } else {
        setError((old: any) => ({ ...old, quantityError: false }))
    }

    setError((old: any) => {
        console.log("IntervallpositionError", old)
        return old
    })


    return valid;
}


function EditCreateIntervallPositionDialog({ open, setOpen, onClose, object, intervall, setIntervall, mode, index }: dialog) {
    const { companyData } = useCompanyData();

    const [exists, setExists] = useState<boolean>(object !== undefined);

    const [product, setProduct] = useState<IProductDTO | undefined>(undefined);

    const [loading, setLoading] = useState<boolean>(false);

    //TODO -> Fabian
    const [intervallPositionToSubmit, setIntervallPositionToSubmit] = useState<IIntervallPosition>({
        subscriptionId: intervall?.id!,
        description: object?.description ? object?.description : "",
        id: object?.id ? object?.id : index,
        netto: object?.netto ? object?.netto : 0,
        organizationId: object?.organizationId ? object?.organizationId : 0,
        productId: object?.productId ? object?.productId : 0,
        quantity: object?.quantity ? object?.quantity : 1,
        tax: object?.tax ? object?.tax : (companyData.kleinunternehmer ? 0 : 20),
        title: object?.title ? object?.title : "",
        discount: object?.discount,
        discountType: object?.discountType,
        hasDiscount: object?.hasDiscount
    });

    useEffect(() => {
        setIntervallPositionToSubmit({
            subscriptionId: intervall?.id!,
            description: object?.description ? object?.description : "",
            id: object?.id ?? index,
            netto: object?.netto ? object?.netto : 0,
            organizationId: object?.organizationId ? object?.organizationId : 0,
            productId: object?.productId ? object?.productId : 0,
            quantity: object?.quantity ? object?.quantity : 1,
            tax: object?.tax ? object?.tax : (companyData.kleinunternehmer ? 0 : 20), //Wenn object bereits existiert, übernehme den Wert, sonst wenn kleinunternehmer dann 0 sonst 20
            title: object?.title ? object?.title : "",
            discount: object?.discount,
            discountType: object?.discountType,
            hasDiscount: object?.hasDiscount
        })
    }, [open, intervall])

    const resetForm = () => {
        setIntervallPositionToSubmit({
            subscriptionId: 0,
            description: "",
            id: undefined,
            netto: 0,
            organizationId: undefined,
            productId: undefined,
            quantity: 1,
            tax: !companyData.kleinunternehmer ? 0 : 20,
            title: "",
            discount: undefined,
            discountType: undefined,
            hasDiscount: undefined
        })
    }

    const connection = useAuthConnection();

    const { addIntervallPosition, updateIntervallPosition } = useIntervalls();


    const [error, setError] = useState<any>({});
    const onSubmitEdit = async () => {
        if (validateIntervallPositionForm(intervallPositionToSubmit, setError)) {
            setLoading(true);

            if (mode === "create") {
                addIntervallPosition({ ...intervallPositionToSubmit, subscriptionId: intervall?.id })
                if (setIntervall) {
                    setIntervall((old: any) => ({ ...old, subscriptionPositions: [...old.subscriptionPositions ?? [], intervallPositionToSubmit] }))

                }
            } else if (mode === "createNewIntervall") {

                if (setIntervall) {
                    if (exists) {
                        setIntervall((old: IIntervallDTO) => ({
                            ...old, subscriptionPositions: old.subscriptionPositions?.map((position) => {
                                if (position.id === intervallPositionToSubmit.id) {
                                    return intervallPositionToSubmit
                                }
                                return position;
                            })
                        }))
                    } else {
                        setIntervall((old: any) => ({ ...old, subscriptionPositions: [...old.subscriptionPositions ?? [], intervallPositionToSubmit] }))
                    }
                }
            }
            else {
                await connection.put("/subscription/subscriptionposition", intervallPositionToSubmit)
            }

            setLoading(false);
            setOpen(false);
            resetForm();
        }
    }

    useEffect(() => { setError({}) }, [intervallPositionToSubmit])

    return (
        <Modal
            open={open}
            title={object !== undefined ? 'Intervallposition bearbeiten' : 'Intervallposition erstellen'}
            onClose={onClose ? onClose : () => setOpen(false)}
        >
            <span>{ }</span>
            <EditCreateIntervallPositionForm
                object={intervallPositionToSubmit}
                setObject={setIntervallPositionToSubmit}
                product={product}
                setProduct={setProduct}
                error={error}
                setError={setError}
            />
            <div style={{ marginBottom: "2%" }}></div>
            <CTAButton
                title={loading ? <CircularProgress /> : exists ? 'Speichern' : 'Erstellen'}
                onClickHandler={onSubmitEdit}
            />
        </Modal>
    )
}

export default EditCreateIntervallPositionDialog