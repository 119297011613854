import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useUser } from "../Contexts/UserContext/UserContext";
import DoubleOptInConfirmationPage from "../Pages/DoubleOptIn/DoubleOptInConfirmationPage";
import Login from "../Pages/Login/Login";
import NewPassword from "../Pages/Login/NewPassword";
import RegisterNewOrganization from "../Pages/Login/RegisterNewOrganization.new";
import Registration from "../Pages/Login/Registration";
import ResetPassword from "../Pages/Login/ResetPassword";
import Cancel from "../Pages/ShoppingCart/Cancel";
import Success from "../Pages/ShoppingCart/Success";

function OnboardingRouter() {
  const { user } = useUser();

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <BrowserRouter>
        <Routes>
          {user?.needsToRegisterOrganization === true && (
            <>
              <Route
                path="/registerneworganization"
                element={<RegisterNewOrganization />}
              />
              <Route
                path="*"
                element={<Navigate to="/registerneworganization" replace />}
              />
            </>
          )}
          {!(user?.needsToRegisterOrganization === true) && (
            <>
              <Route path="/success" element={<Success />} />
              <Route path="/cancel" element={<Cancel />} />
              <Route path="/" element={<Login />} />
              <Route path="/register" element={<Registration />} />
              <Route path="/reset" element={<ResetPassword />} />
              <Route path="/reset/:code" element={<NewPassword />} />
              <Route path="*" element={<Navigate to="/" replace />} />
              <Route
                path="/verifydoubleoptin"
                element={<DoubleOptInConfirmationPage />}
              />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default OnboardingRouter;
