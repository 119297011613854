import { useEffect, useRef, useState } from "react";
import CTAButton from "../Buttons/CTAButton/CTAButton";
import { Input } from "../UI/Input";
import { IProductDTO, useProducts } from "../../Contexts/ProductContext/ProductProvider";
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "../UI/Dialog";
import { IconButton, Tooltip } from "@mui/material";
import { ScanBarcode } from "lucide-react";
import { useSymbologyScanner } from "@use-symbology-scanner/react";
import { useSnackbar } from "notistack";
import ScannedProductRow from "./ScannedProductRow";
import { Button } from "../UI/Button";

type ProductScannerProps = {
    setScannedProductsInParent?: React.Dispatch<React.SetStateAction<IProductDTO[]>>
}

export default function ProductScanner({ setScannedProductsInParent }: ProductScannerProps) {

    const { enqueueSnackbar } = useSnackbar();
    const { products } = useProducts();
    const focusRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);
    const [scannedProducts, setScannedProducts] = useState<IProductDTO[]>([]);
    const [scannedProductErrors, setScannedProductErrors] = useState<{ productId: number, message: string }[]>([])

    const handleScannerInput = (value: string) => {
        console.log(value);

        if (!value || value.length === 0 || !open) return;
        const product = products.find(p => p.codeValue === value);

        if (product) {
            if (isProductInScannedProducts(product)) {
                incrementProductCount(product);
                return;
            }
            const productWithCount = { ...product, count: 1 };
            setScannedProducts([...scannedProducts, productWithCount]);
        }
        else {
            enqueueSnackbar("Produkt nicht gefunden", { variant: "error", autoHideDuration: 1000 });
        }
    }

    useSymbologyScanner(handleScannerInput, { scannerOptions: { prefix: "", suffix: "\n" } });

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger>
                <Tooltip title="Produkte scannen">
                    <IconButton><ScanBarcode /></IconButton>
                </Tooltip>
            </DialogTrigger>
            <DialogContent ref={focusRef} autoFocus={true}>
                <DialogHeader>
                    <DialogTitle>
                        Produkte scannen
                    </DialogTitle>

                </DialogHeader>
                <div>
                    Gescannte Produkte
                </div>
                {scannedProducts.map((p) =>
                    <ScannedProductRow errorMessage={scannedProductErrors.find(e => e.productId === p.id)?.message} focusRef={focusRef} key={p.id} product={p} setProductCount={(count: number) => setProductCount(p, count)} removeProduct={() => removeScannedProduct(p)} />
                )}
                <DialogFooter className="sm:justify-center mt-4 w-full">
                    {/* <DialogClose asChild> */}
                        <Button onClick={handleProductSubmit} disabled={scannedProducts.length === 0} variant={"cta"} >Produkte übernehmen</Button>
                    {/* </DialogClose> */}
                </DialogFooter>
            </DialogContent>
        </Dialog>

    )

    function isProductInScannedProducts(product: IProductDTO) {
        const index = scannedProducts.findIndex(p => p.id === product.id);
        if (index === -1) return false;

        return true;
    }

    function setProductCount(product: IProductDTO, count: number) {
        const index = scannedProducts.findIndex(p => p.id === product.id);
        const newScannedProducts = [...scannedProducts];
        newScannedProducts[index] = { ...product, count: count };
        setScannedProducts(newScannedProducts);
    }

    function incrementProductCount(product: IProductDTO) {
        const index = scannedProducts.findIndex(p => p.id === product.id);
        const newScannedProducts = [...scannedProducts];
        newScannedProducts[index] = { ...product, count: (scannedProducts[index].count! + 1) };
        setScannedProducts(newScannedProducts);
        focusRef.current?.focus();
    }

    function removeScannedProduct(product: IProductDTO) {
        const index = scannedProducts.findIndex(p => p.id === product.id);
        const newScannedProducts = [...scannedProducts];
        newScannedProducts.splice(index, 1);
        setScannedProducts(newScannedProducts);
        focusRef.current?.focus();
    }

    function handleProductSubmit() {
        console.log("handle product submit called");
        setScannedProductErrors([]);
        const errors = validateScannedProductsInput();

        if (errors.length > 0) {
            setScannedProductErrors(errors);
            return;
        }

        if (setScannedProductsInParent) setScannedProductsInParent(scannedProducts);
        resetState()
        setOpen(false);
    }

    function resetState() {
        setScannedProducts([]);
    }

    function validateScannedProductsInput() {
        let errors: { productId: number; message: string; }[] = [];

        scannedProducts.forEach((scannedProduct) => {
            if (scannedProduct.count === undefined || scannedProduct.count === null) {
                errors.push({ productId: scannedProduct.id, message: "Produktanzahl muss angegeben werden" });
            }

            if (!scannedProduct.count || scannedProduct.count <= 0) {
                errors.push({ productId: scannedProduct.id, message: "Produktanzahl muss größer als 0 sein." });
            }

            if (scannedProduct.count && scannedProduct.count > 99999) {
                errors.push({ productId: scannedProduct.id, message: "Produktanzahl darf nicht größer als 99999 sein." });
            }
        })
        return errors;
    }
}