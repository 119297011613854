import React, { useEffect, useState } from "react";
import { useContacts } from "../../Contexts/ContactsContext/ContactsProvider";
import { useServices } from "../../Contexts/ServiceContext/ServiceContext";
import { IOrganization } from "../../Interfaces/Company";
import { ITask } from "../../Interfaces/Task";
import './LiveTicker.css';
//import { useTasks } from "../../Contexts/TaskContext/TaskContext";
import { useCompanyData } from "../../Contexts/CompanyDataContext/CompanyDataProvider";
import { useIntervalls } from "../../Contexts/IntervallContext/IntervallProvider";
import { useTasks } from "../../Contexts/TaskContext/TaskContext";
import "./Newsline.css";
import { IServiceDTO } from "../../Interfaces/Service";
import { useGetAllServicesQuery } from "../../redux/apiSlices/Service";


let openServicesCnt = 0;
let kundenCnt = 0;
let firmenCnt = 0;
let personenCnt = 0;
let tasksCnt = 0;
let interneTasksCnt = 0;
let externeTasksCnt = 0;
let intervalleCnt = 0;


const NEWS_ITEMS = [

    "Text oder noch ein längerer Text",

];

const LiveTicker: React.FC = () => {
    const [tickerItems, setTickerItems] = useState([""]);

    //Anzahl d. Firmen
    const { companies } = useContacts();
    let tempCompanies = companies;
    firmenCnt = tempCompanies.length;

    //Anzahl offene Leistungen
    const { data: services, isLoading: isServicesLoading } = useGetAllServicesQuery();
    let tempServices = services ? services.filter((service: IServiceDTO) => service.billed === false) : [] //tempServices sind jetzt alle Leistungen die noch nicht verrechnet wurden
    openServicesCnt = tempServices.length

    //Anzahl Personen
    const { persons } = useContacts();
    let tempPersons = persons;
    personenCnt = tempPersons.length;

    //Anzahl Kunden
    let tempCustomer = companies.filter((company: IOrganization) => company.customer === true)
    kundenCnt = tempCustomer.length;

    //Anzahl Intervalle
    const {intervalls} = useIntervalls();
    let tempIntervalle = intervalls;
    intervalleCnt = tempIntervalle.length;

    //Anzahl offene Tasks
    const { tasks, tasksUnformated } = useTasks();
    let tempTasks = tasksUnformated;
    /*In TypeScript haben Arrays die Eigenschaft "length", aber das Objekt tempTasks ist ein assoziatives Array. 
    Ein assoziatives Array (auch bekannt als "Dictionary" oder "Map") ist ein Objekt, das Schlüssel-Wert-Paare enthält, wobei der Schlüssel kein numerischer Index ist.
    Wenn die Anzahl der Elemente gefragt ist, muß man die "Object.keys" Methode verwenden, um ein Array der Schlüssel zu erhalten,
    und dann die Länge des resultierenden Arrays ermitteln: */
    const objectKeys = Object.keys(tempTasks);
    tasksCnt = objectKeys.length; //gibt nur meine Tasks zurück, und dabei nur solche, die einem Kunden zugeordnet sind.

    //Anzahl interner Tasks
    const { companyData} = useCompanyData(); //CompanyData ist immer die eigene Firma!
    interneTasksCnt = tasksUnformated.filter((task: ITask) => task.customerOrganizationId === companyData.id).length;

    //Anzahl externe Tasks
    externeTasksCnt = tasksUnformated.filter((task: ITask) => task.customerOrganizationId != companyData.id).length;
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                setTickerItems([
                    "Zu Verrechnen: " + openServicesCnt,
                    "Kunden: " + kundenCnt,
                    "Organisationen: " + firmenCnt,
                    "Personen: " + personenCnt,
                    "Aufgaben: " + tasksCnt,
                    "Interne Aufgaben: " + interneTasksCnt,
                    "Externe Aufgaben: " + externeTasksCnt,
                    "Intervalle: " + intervalleCnt
                ]);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();

        const interval = setInterval(() => {
            fetchData();
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    // Set the --ticker-items CSS variable to the length of tickerItems
    const tickerItemCount = tickerItems.length;
    const tickerStyles = { "--ticker-items": tickerItemCount };


    return (
        <div className="newsline-container">
            {tickerItems.map((item, index) => (
                <div key={index} className="news-item">
                    {item}
                </div>
            ))}
        </div>
    );
};


export default LiveTicker;
