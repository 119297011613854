import { ArrowForward, CloseRounded } from "@mui/icons-material";
import { Checkbox, CircularProgress, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import InputField from "../../Components/InputField/InputField";
import { useUser } from "../../Contexts/UserContext/UserContext";
import { SavedUser } from "../../Interfaces/User";

/* Icons */
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { baseConnection } from "../../Connection/BaseConnection";
import { useMobileView } from "../../Hooks/useMobileView";

function HeaderElement({
  user,
  setExpanded,
  expanded,
  setActiveUser,
}: {
  user: SavedUser | null;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  expanded: boolean;
  setActiveUser: React.Dispatch<React.SetStateAction<SavedUser | null>>;
}) {
  return (
    <div className={"flex stretched-justify"}>
      <div className="flex centered-aligned">
        <div>{/* Company-Logo */}</div>
        <div className="flex column">
          {/* Accout-Daten */}
          <span className="heading">{user?.company}</span>
          <span
            className="heading-small"
            style={{ color: "var(--text-contrast)" }}
          >
            {user?.email}
          </span>
        </div>
      </div>
      <div className="flex" style={{ gap: "5px" }}>
        {/* Button-Sektion */}
        <IconButton onClick={() => setExpanded((val) => !val)}>
          <KeyboardArrowDownRoundedIcon fontSize="small" />
        </IconButton>
        <IconButton onClick={() => setActiveUser(null)}>
          <CloseRounded fontSize="small" />
        </IconButton>
      </div>
    </div>
  );
}

function Registration() {
  //Login-Funktion aus dem User-Context -> Syntax dekonstruiert das Objekt, das useUser() returned. -> Nur die Werte/Funktionen in den geschwungenen Klammern können verwendet werden, wenn diese Syntax gewählt wurde.
  //Der Wert nach den zwei Rufzeichen geben den Fallback-Wert an, der verwendet wird, wenn der Wert links neben den ?? undefined ist.
  const { login, accounts } = useUser() ?? {};

  const [registrationData, setRegistrationData] = useState<{
    username: string;
    email: string;
    password: string;
    passwordCheck: string;
    receivesNewsletter: boolean;
    organisationName: string;
  }>({
    username: "",
    email: "",
    password: "",
    passwordCheck: "",
    receivesNewsletter: false,
    organisationName: "",
  });

  const registrationDataRef = useRef<{
    username: string;
    email: string;
    password: string;
  }>();
  registrationDataRef.current = registrationData;

  //Regelt das Anzeigen eines Spinners innerhalb des Ladebuttons
  const [loading, setLoading] = useState(false);

  //Regelt Anzeigen der "Registrierung erfolgreich" Seite
  const [registerSuccess, setRegisterSuccess] = useState(true);

  const mobile = useMobileView();

  //Kombiniert die LoginFunktion mit dem Lade-State der Seite
  const handleRegistration = async () => {
    try {
      setLoading(true);

      baseConnection
        .post("/Authentication/SignUp", registrationData)
        .then((res: AxiosResponse) => {
          if (res.status === 201) {
            setRegisterSuccess(false);
          }
        });

      //await login!((activeElement?.email ?? loginDataRef.current!.email), loginDataRef.current!.password)
    } catch (error) {
      //TODO Ein Fehlerstate zum Anzeigen einer Fehlermeldung bei fehlgeschlagener Anmeldung -> Fabian oder wer Interesse hat
    } finally {
      setLoading(false);
    }
  };

  const [usernameAvailable, setUsernameAvailable] = useState(true);

  const onUsernameBlur = async () => {
    if (registrationData.username == "") {
      setUsernameAvailable(true);
    } else {
      try {
        baseConnection
          .get(`/Authentication/check/${registrationData.username}`)
          .then((res: AxiosResponse) => {
            if (res.status === 299) {
              setUsernameAvailable(false);
            }

            if (res.status === 200) {
              setUsernameAvailable(true);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const options: any = [];

  const renderOptions = (item: any) => {
    const { option, setActiveElement } = item;
    return <div onClick={() => setActiveElement(option)}>{option.company}</div>;
  };

  const [activeElement, setActiveElement] = useState<SavedUser | null>(
    options[0] ?? null
  );

  const navigation = useNavigate();

  //variables to validate user input
  const [errorUsername, setErrorUsername] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorPasswordCheck, setErrorPasswordCheck] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);

  //functions to validate user input
  function containsSpecialChars(str: string) {
    const specialChars = /[` !@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }

  function usernameLength(username: string) {
    const minLength = 3;
    const maxLength = 50;

    return username.length >= 3 && username.length <= 50;
  }

  function checkUsername(username: string) {
    if (username == "") {
      setErrorUsername(false);
      setUsernameAvailable(true);
      return;
    }

    if (containsSpecialChars(username)) {
      setErrorUsername(true);
      return;
    } else {
      setErrorUsername(false);
    }

    if (!usernameLength(username)) {
      setErrorUsername(true);
    } else {
      setErrorUsername(false);
    }
  }

  function checkPassword(password: string) {
    const minLength = 6;
    const maxLength = 100;

    if (password.length >= 6 && password.length <= 100) {
      setErrorPassword(false);
      return;
    }

    if (password == "") {
      setErrorPassword(false);
      return;
    }

    if (password.length < 6 || password.length > 100) {
      setErrorPassword(true);
      return;
    }
  }

  function checkPasswordConfirmation(password: string, passwordCheck: string) {
    if (passwordCheck == "") {
      setErrorPasswordCheck(false);
      return;
    }

    if (password === passwordCheck) {
      setErrorPasswordCheck(false);
      return;
    }

    if (password !== passwordCheck) {
      setErrorPasswordCheck(true);
    }
  }

  function checkEmail(email: string) {
    const allowed =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email == "") {
      setErrorEmail(false);
      return;
    }

    if (!allowed.test(email)) {
      setErrorEmail(true);
    } else {
      setErrorEmail(false);
    }
  }

  const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);

  useEffect(() => {
    checkUsername(registrationData.username);
    checkPassword(registrationData.password);
    checkPasswordConfirmation(
      registrationData.password,
      registrationData.passwordCheck
    );
    checkEmail(registrationData.email);

    if (
      usernameAvailable &&
      !errorEmail &&
      !errorPassword &&
      !errorPasswordCheck &&
      !errorUsername &&
      registrationData.username !== "" &&
      registrationData.password !== "" &&
      registrationData.email !== "" &&
      registrationData.passwordCheck !== ""
    ) {
      setContinueButtonDisabled(false);
    } else {
      setContinueButtonDisabled(true);
    }

    function onKeyup(e: KeyboardEvent) {
      if (e.key === "Enter" && !continueButtonDisabled) handleRegistration();
    }

    console.log(registrationData);

    window.addEventListener("keyup", onKeyup);
    return () => window.removeEventListener("keyup", onKeyup);
  }, [
    registrationData.username,
    registrationData.password,
    registrationData.receivesNewsletter,
    registrationData.passwordCheck,
    registrationData.email,
    errorEmail,
    errorPassword,
    errorPasswordCheck,
    errorUsername,
    usernameAvailable,
    continueButtonDisabled,
  ]);

  return (
    <div className="flex justify-center items-center h-screen">
      <section className="w-full lg:w-[75%] bg-background rounded-lg p-10 flex flex-col gap-3 max-h-screen overflow-y-auto">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <a
            href="https://ecockpit.at/"
            target="_blank"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <img
              src="https://ecockpit.at/wp-content/uploads/2022/08/eCockpit-Logo.png"
              style={{ width: "33%" }}
            ></img>
          </a>
        </div>
        <div
          className="flex centered"
          style={{
            marginTop: "1%",
            marginBottom: "1%",
            fontSize: "3vh",
            fontWeight: "600",
          }}
        >
          Registrierung
        </div>
        {registerSuccess && (
          <>
            <InputField
              error={errorUsername}
              isRegister
              required
              placeholder="Organisationsname"
              label="Organisationsname:"
              value={registrationData.organisationName}
              onChangeHandler={(organisationName) =>
                setRegistrationData((prevData) => ({
                  ...prevData,
                  organisationName,
                }))
              }
              errorMessage="Organisationsname muss zwischen 3-50 Zeichen lang sein!"
            />
            <InputField
              onBlurHandler={onUsernameBlur}
              usernameAvailable={usernameAvailable}
              error={errorUsername}
              isRegister
              required
              placeholder="Benutzername "
              label="Benutzername:"
              value={registrationData.username}
              onChangeHandler={(username) =>
                setRegistrationData((prevData) => ({ ...prevData, username }))
              }
              errorMessage="Benutzername muss zwischen 3-50 Zeichen lang sein und darf keine Sonderzeichen beinhalten!"
            />
            <InputField
              error={errorPassword}
              isRegister
              required
              placeholder="Passwort"
              label="Passwort"
              isSecure
              value={registrationData.password}
              onChangeHandler={(password) =>
                setRegistrationData((prevData) => ({ ...prevData, password }))
              }
              errorMessage="Passwort muss mindestens 6 Zeichen lang sein!"
            />
            <InputField
              error={errorPasswordCheck}
              isRegister
              required
              placeholder="Passwort bestätigen"
              label="Passwort bestätigen"
              isSecure
              value={registrationData.passwordCheck}
              onChangeHandler={(passwordCheck) =>
                setRegistrationData((prevData) => ({
                  ...prevData,
                  passwordCheck,
                }))
              }
              errorMessage="Passwörter stimmen nicht überein!"
            />
            <InputField
              error={errorEmail}
              isRegister
              required
              placeholder="Email-Adresse"
              label="Email-Adresse:"
              value={registrationData.email}
              onChangeHandler={(email) =>
                setRegistrationData((prevData) => ({ ...prevData, email }))
              }
              errorMessage="Geben Sie eine gültige E-Mail Adresse ein!"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Checkbox
                value={registrationData.receivesNewsletter}
                onChange={(event) =>
                  setRegistrationData((old) => ({
                    ...old,
                    receivesNewsletter: event.target.checked,
                  }))
                }
                sx={{
                  color: "#390099",
                  "&.Mui-checked": {
                    color: "#390099",
                  },
                }}
              />
              <span>
                Ich möchte einen auf meine Interessen zugeschnittenen Newsletter
                erhalten.
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                marginTop: "0.25em",
              }}
            >
              <span>
                Es gelten die{" "}
                <a
                  target="_blank"
                  href="https://ecockpit.at/allgemeine-geschaeftsbedingungen/"
                >
                  AGB
                </a>{" "}
                &{" "}
                <a
                  target="_blank"
                  href="https://ecockpit.at/impressum-datenschutz/"
                >
                  Datenschutzerklärung
                </a>
                .
              </span>
            </div>
            <div
              className="flex stretched-justify"
              style={{ marginTop: "20px" }}
            >
              <button
                className="secondary-button pointer"
                onClick={() => navigation("/")}
              >
                Sie haben bereits ein Konto? Klicken Sie hier!
              </button>
              {!continueButtonDisabled && (
                <button
                  onClick={async () => handleRegistration()}
                  className="cta-button pointer"
                  style={{ width: "40%" }}
                >
                  {
                    loading ? (
                      <CircularProgress size={"12px"} color="inherit" />
                    ) : (
                      <span style={{ gap: "10px" }} className="flex centered">
                        Weiter
                        <ArrowForward fontSize="small" />
                      </span>
                    ) /*Konditionales Rendern*/
                  }
                </button>
              )}
              {continueButtonDisabled && (
                <button
                  disabled
                  onClick={async () => handleRegistration()}
                  className="cta-button-disabled not-allowed"
                  style={{ width: "40%" }}
                >
                  {
                    loading ? (
                      <CircularProgress size={"12px"} color="inherit" />
                    ) : (
                      <span style={{ gap: "10px" }} className="flex centered">
                        Weiter
                        <ArrowForward fontSize="small" />
                      </span>
                    ) /*Konditionales Rendern*/
                  }
                </button>
              )}
            </div>
          </>
        )}

        {!registerSuccess && (
          <div className="text-center">
            Registrierung war erfolgreich! Bitte bestätigen Sie ihre Email.
          </div>
        )}
      </section>
    </div>
  );
}

export default Registration;
