import { AvatarGroup, Avatar, Tooltip } from "@mui/material";
import { useState, useEffect } from "react";
import OnlineStaffMenu from "./Components/OnlineStaff/OnlineStaffMenu";
import { useStaff, StaffDTO } from "./Contexts/StaffContext/StaffProvider";
import { useUser } from "./Contexts/UserContext/UserContext";
import { useMobileView } from "./Hooks/useMobileView";

export default function Header() {
    const isMobile = useMobileView()

    const { staff } = useStaff();
    const { user } = useUser();

    const [filteredStaff, setFilteredStaff] = useState<any>([]);

    useEffect(() => { setFilteredStaff(staff.filter((member: StaffDTO) => member.active && member.workingStatus === 1)); }, [staff])

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const handleOptionsClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }

    return (
        <>
            {!isMobile &&
                <div className="bg-default flex justify-between h-[5%] min-h-[60px]">
                    <div>

                    </div>
                    <div className="flex items-center gap-2 mr-5">
                        <span className="">
                            {filteredStaff.length > 0 ? "Online:" : "Zur Zeit ist niemand da"}
                        </span>
                        <AvatarGroup max={5} onClick={handleOptionsClick}>
                            {filteredStaff.map((member: StaffDTO) =>
                                <Tooltip key={member.id} title={getTooltipTitleForStaffMember(member)}>
                                    <Avatar key={member.id} alt={member.person?.lastname} src={`data:image/jpeg;base64,${member?.base64}`} >
                                        {member.person?.lastname?.charAt(0)}{member.person?.firstname?.charAt(0)}
                                    </Avatar>
                                </Tooltip>
                            )}
                        </AvatarGroup>
                    </div>
                </div>
            }
        </>
    )

    function getTooltipTitleForStaffMember(member: StaffDTO): string {
        if (member.person?.completeName && member.person.completeName.length > 0) {
            return member.person.completeName
        }

        return member.username
    }
}