import { IconButton } from "@mui/material";
import dayjs from "dayjs";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useState } from "react";
import { ITravel } from "../../Interfaces/Travel";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../UI/Collapsible";
import TravelSelect, { TravelSelectProps } from "./TravelSelect";

type TravelSelectWithInfoProps = TravelSelectProps & {};

export default function TravelSelectWithInfo({
  onSelectionChange,
  initialSelectedTravels,
}: TravelSelectWithInfoProps) {
  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(false);
  const [selectedTravels, setSelectedTravels] = useState<ITravel[]>([]);

  const handleTravelSelectChange = (travels: ITravel[]) => {
    setSelectedTravels(travels);
    onSelectionChange(travels);
  };

  return (
    <>
      <TravelSelect
        onSelectionChange={handleTravelSelectChange}
        initialSelectedTravels={initialSelectedTravels}
      />
      {/*selectedTravels &&
        selectedTravels.length > 0 &&
        selectedTravels.map((selectedTravel) => (
          <Collapsible
            key={selectedTravel.id}
            open={isCollapsibleOpen}
            onOpenChange={setIsCollapsibleOpen}
          >
            <div className="ml-4 mr-4 text-sm bg-purple-50">
              <div className="font-semibold flex items-center">
                <CollapsibleTrigger asChild>
                  {!isCollapsibleOpen ? (
                    <IconButton>
                      <ChevronDown />
                    </IconButton>
                  ) : (
                    <IconButton>
                      <ChevronUp />
                    </IconButton>
                  )}
                </CollapsibleTrigger>
                Infos zur Fahrt {selectedTravel.title}
              </div>
              <CollapsibleContent>
                <div className="ml-10">
                  <div className="flex flex-col">
                    <div>
                      Datum: {dayjs(selectedTravel.date).format("DD.MM.YYYY")}
                    </div>
                    <div>Distanz: {selectedTravel.distance}km</div>
                    <div>{selectedTravel.userIds}</div>
                  </div>
                </div>
              </CollapsibleContent>
            </div>
          </Collapsible>
        ))*/}
    </>
  );
}
