import { IProductDTO } from "../../Contexts/ProductContext/ProductProvider"
import { IDeliveryNoteDTO, IDeliveryNotePosition } from "../../Pages/Services/LieferscheinErstellen"

export const convertDeliveryNotePositionToProduct = (deliveryNotePosition: IDeliveryNotePosition) => {
    const product: IProductDTO = {
        id: deliveryNotePosition.productId ?? 0,
        title: deliveryNotePosition.title,
        description: deliveryNotePosition.description,
        count: deliveryNotePosition.quantity,
        salesPrice: deliveryNotePosition.netto,
        organizationId: deliveryNotePosition.organizationId,
    }
    return product;
}

export const convertDeliveryNotePositionsToProducts = (deliveryNotePositions: IDeliveryNotePosition[] | undefined) => {
    const products: IProductDTO[] = [];

    if (!deliveryNotePositions) {
        return products;
    }

    deliveryNotePositions.forEach(deliveryNotePosition => {
        const product = convertDeliveryNotePositionToProduct(deliveryNotePosition);
        products.push(product);
    })

    return products;
}