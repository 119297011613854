import { IconButton, Tooltip } from "@mui/material";
import { Popover, PopoverContent, PopoverTrigger } from "../UI/Popover";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { SetStateAction, useEffect, useState } from "react";
import { Dayjs } from "dayjs";
import { Input } from "../UI/Input";
import { ITask, ITaskDTO } from "../../Interfaces/Task";
import { GroupedTasks } from "./TaskCard";
import { useUser } from "../../Contexts/UserContext/UserContext";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import { Check, LucideX } from "lucide-react";
import { User } from "../../Interfaces/User";
import { groupBy } from "../../Hooks/Utilities/Utlitites";

export type TaskFilters = {
  title?: string;
  description?: string;
  dateFrom?: Dayjs;
  dateTo?: Dayjs;
  cardMode?: taskCardMode;
};

type TaskFilterProps = {
  onChange: (filters: TaskFilters) => void;
  setParentCardMode: React.Dispatch<SetStateAction<taskCardMode>>;
};

export enum taskCardMode {
  showOwnTasks = 1,
  showAllTaks = 2,
  showFinishedTasks = 3,
  showFinishedTasksOfUser = 4,
}
export default function TaskFilter({
  onChange,
  setParentCardMode,
}: TaskFilterProps) {
  const { user } = useUser();
  const [cardMode, setCardMode] = useState<taskCardMode>(
    taskCardMode.showOwnTasks
  );
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  useEffect(() => {
    onChange({
      title,
      description,
      cardMode,
    });
    setParentCardMode(cardMode);
  }, [title, description, cardMode]);

  return (
    <div className="flex">
      {user?.organizationRoleName === "Admin" ||
      user?.organizationRoleName === "Owner" ? (
        <div className="rounded-full flex gap-1">
          <div
            className={`rounded-full border ${
              cardMode === taskCardMode.showOwnTasks && "bg-brand-light"
            }`}
          >
            <Tooltip title="Meine Aufgaben">
              <IconButton
                onClick={() => setCardMode(taskCardMode.showOwnTasks)}
              >
                <PersonIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div
            className={`rounded-full border ${
              cardMode === taskCardMode.showAllTaks && "bg-brand-light"
            }`}
          >
            <Tooltip title="Alle Aufgaben">
              <IconButton onClick={() => setCardMode(taskCardMode.showAllTaks)}>
                <BusinessIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div
            className={`rounded-full border ${
              cardMode === taskCardMode.showFinishedTasks && "bg-brand-light"
            }`}
          >
            <Tooltip title="Fertiggestellte Aufgaben">
              <IconButton
                onClick={() => setCardMode(taskCardMode.showFinishedTasks)}
              >
                <Check />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      ) : (
        <div className="rounded-full flex gap-1">
          <div
            className={`rounded-full border ${
              cardMode === taskCardMode.showOwnTasks && "bg-brand-light"
            }`}
          >
            <Tooltip title="Meine Aufgaben">
              <IconButton
                onClick={() => setCardMode(taskCardMode.showOwnTasks)}
              >
                <PersonIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div
            className={`rounded-full border ${
              cardMode === taskCardMode.showFinishedTasks && "bg-brand-light"
            }`}
          >
            <Tooltip title="Fertiggestellte Aufgaben">
              <IconButton
                onClick={() => setCardMode(taskCardMode.showFinishedTasks)}
              >
                <Check />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
      <div className="flex">
        <Popover>
          <PopoverTrigger>
            <IconButton>
              <FilterAltIcon />
            </IconButton>
          </PopoverTrigger>
          <PopoverContent>
            <div className="flex flex-col justify-center items-center gap-2">
              <div className="flex flex-row">
                <Input
                  placeholder="Titel"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <IconButton onClick={() => setTitle("")}>
                  <LucideX />
                </IconButton>
              </div>
              <div className="flex flex-row">
                <Input
                  placeholder="Beschreibung"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <IconButton onClick={() => setDescription("")}>
                  <LucideX />
                </IconButton>
              </div>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
}

export function filterTaskList(
  taskList: ITask[],
  setFilteredTaskList: React.Dispatch<React.SetStateAction<GroupedTasks>>,
  filters: TaskFilters,
  user: User | undefined | null
) {
  let filteredTaskList: GroupedTasks = groupBy!(
    [
      ...taskList?.filter((element: ITaskDTO) => {
        return (
          filters.cardMode === taskCardMode.showAllTaks ||
          element.userIds?.includes(user?.userId!)
        );
      }),
    ],
    "customerOrganizationId"
  );

  if (filters.cardMode) {
    filteredTaskList = Object.fromEntries(
      Object.entries(filteredTaskList).map(([key, tasks]) => [
        key,
        tasks.filter((task) =>
          filters.cardMode === taskCardMode.showOwnTasks
            ? task.userIds?.includes(user?.userId!) && !task.finished
            : filters.cardMode === taskCardMode.showAllTaks
            ? !task.finished
            : filters.cardMode === taskCardMode.showFinishedTasksOfUser
            ? task.userIds?.includes(user?.userId!) && task.finished
            : task.finished
        ),
      ])
    );
  }

  if (filters.title) {
    filteredTaskList = Object.fromEntries(
      Object.entries(filteredTaskList).map(([key, tasks]) => [
        key,
        tasks.filter((task) =>
          task.title?.toLowerCase().includes(filters.title!.toLowerCase())
        ),
      ])
    );
  }

  if (filters.description) {
    filteredTaskList = Object.fromEntries(
      Object.entries(filteredTaskList).map(([key, tasks]) => [
        key,
        tasks.filter((task) =>
          task.description
            ?.toLowerCase()
            .includes(filters.description!.toLowerCase())
        ),
      ])
    );
  }

  setFilteredTaskList(filteredTaskList);
}
