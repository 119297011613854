import { createApi } from "@reduxjs/toolkit/query/react";
import { baseAuthQuery } from "../baseAuthQuery";

// Define the MailCountDTO interface
export interface MailCountDTO {
    count: number;
    sent: number;
}

// Create the API slice for MailCount
export const MailApi = createApi({
    reducerPath: "MailApi",
    baseQuery: baseAuthQuery,
    tagTypes: ["MailCount"],
    endpoints: (builder) => ({
        getMailCount: builder.query<MailCountDTO, void>({
            query: () => ({
                url: "/email/mailcount"
            }),
        })
    })
});

// Export hooks for the API
export const {
    useGetMailCountQuery
} = MailApi;
