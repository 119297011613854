import { Dayjs } from "dayjs"

export interface IBillDTO {
    id?: number,
    organizationId?: number,
    organizationName?: string,
    customerId?: number,
    personId?: number,
    customerName?: string,
    prefix?: number,
    billPrefix?: string,
    billNumber?: number,
    document?: number,
    account?: number,
    positiveAmount?: boolean,
    isCreated?: boolean,
    isReceipt?: boolean,
    text?: string,
    textBefore?: string
    textAfter?: string
    date?: Date
    from?: Date
    to?: Date
    netto?: number
    incoming?: boolean
    isPaid?: boolean
    isSend?: boolean
    note?: string
    isOffer?: boolean
    tasksGenerated?: boolean
    paymentDate?: Date
    generatePDF?: boolean
    rounding?: number
    subscriptionId?: number
}

export const DiscountType = {
    Percentage: false,
    FixedValue: true
}


export interface IBillingPosition {
    id?: number,
    billId?: number,
    isCreated?: boolean,
    title?: string,
    description?: string,
    netto?: number,
    tax?: number,
    organizationId?: number,
    quantity?: number,
    productId?: number | undefined,
    serviceId?: number | undefined
    serviceEmployeeDataId?: number | undefined
    from?: Dayjs | undefined
    to?: Dayjs | undefined
    hasDiscount?: boolean
    discountType?: boolean
    discount?: number
    unitId?: number
}

export interface IBillListDTO {
    bill: IBillDTO,
    billingPositions: Array<IBillingPosition>
    file?: File
    emailStatus?: 'delivered' | 'bounce' | 'defer' | 'block'
}

export interface IUnit {
    id: number
    title: string
}

export const parseBillEmailStatus = (emailStatus: string | undefined) => {
    switch (emailStatus) {
        case "delivered":
            return "(Zugestellt)";
        case "open":
            return "(Gesehen)";
        case "spam":
            return "(Im Spam)";
        case "dropped":
            return "(Unzustellbar)";
        case "deferred":
            return "(Unzustellbar)";
        case "bounced":
            return "(Unzustellbar)";
        default:
            return ""
    }
}