import { useEffect, useRef, useState } from "react";
import CTAButton from "../Buttons/CTAButton/CTAButton";
import { Input } from "../UI/Input";
import { IProductDTO, useProducts } from "../../Contexts/ProductContext/ProductProvider";
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "../UI/Dialog";
import { IconButton, Tooltip } from "@mui/material";
import { ScanBarcode } from "lucide-react";
import { useSymbologyScanner } from "@use-symbology-scanner/react";
import { useSnackbar } from "notistack";
import ScannedProductRow from "./ScannedProductRow";
import { Button } from "../UI/Button";
import ScannedSerialRow from "./ScannedSerialRow";
import { IDeliveryNotePositionSerialNumber } from "../../Pages/Services/LieferscheinErstellen";

type SerialNumberScannerProps = {
    updateParentSerialNumbers?: (serialNumbers: IDeliveryNotePositionSerialNumber[]) => void;
    positionIndex?: number;
    deliveryPositionId: number;
};

export default function SerialNumberScanner({ updateParentSerialNumbers, positionIndex, deliveryPositionId }: SerialNumberScannerProps) {

    const { enqueueSnackbar } = useSnackbar();
    const focusElementRef = useRef<HTMLDivElement>(null);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [scannedSerialNumbers, setScannedSerialNumbers] = useState<IDeliveryNotePositionSerialNumber[]>([]);

    const handleBarcodeScan = (scannedValue: string) => {
        if (!isDialogOpen) return;

        focusElementRef.current?.focus(); // Ensure barcode scanner input is handled properly
        
        if (!scannedValue || scannedValue.trim().length === 0) {
            enqueueSnackbar("Unable to capture serial number", { variant: "error", autoHideDuration: 1000 });
            return;
        }

        if (!isSerialNumberDuplicate(scannedValue)) {
            setScannedSerialNumbers((prevList) => ([
                ...prevList,
                { serialNumber: scannedValue, deliveryNotePositionId: deliveryPositionId }
            ]));
        } else {
            enqueueSnackbar("Serial number already captured", { variant: "error", autoHideDuration: 1000 });
        }
    };

    useSymbologyScanner(handleBarcodeScan, { scannerOptions: { prefix: "", suffix: "\n" } });

    return (
        <Dialog open={isDialogOpen} onOpenChange={setDialogOpen}>
            <DialogTrigger>
                <Tooltip title="Scan Serial Numbers">
                    <IconButton><ScanBarcode /></IconButton>
                </Tooltip>
            </DialogTrigger>
            <DialogContent ref={focusElementRef} autoFocus={true}>
                <DialogHeader>
                    <DialogTitle>
                        Scan Serial Numbers
                    </DialogTitle>
                </DialogHeader>
                <div>
                    Scanned Serial Numbers
                </div>
                {scannedSerialNumbers.map((serial, idx) => (
                    <ScannedSerialRow
                        focusRef={focusElementRef}
                        key={idx}
                        serialNumber={serial.serialNumber}
                        updateSerialNumber={(updatedSerial) => updateSerialNumberAtIndex(updatedSerial, idx)}
                        removeSerialNumber={() => removeSerialNumberAtIndex(idx)}
                        index={idx}
                    />
                ))}
                <DialogFooter className="sm:justify-center mt-4 w-full">
                    <Button onClick={submitSerialNumbers} variant={"cta"}>Submit Serial Numbers</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );

    function isSerialNumberDuplicate(serialNumber: string): boolean {
        return scannedSerialNumbers.some(serial => serial.serialNumber === serialNumber);
    }

    function removeSerialNumberAtIndex(index: number): void {
        setScannedSerialNumbers((prevList) => {
            const updatedList = [...prevList];
            updatedList.splice(index, 1);
            return updatedList;
        });

        focusElementRef.current?.focus();
    }

    function updateSerialNumberAtIndex(updatedSerialNumber: string, index: number): void {
        setScannedSerialNumbers((prevList) => {
            const updatedList = [...prevList];
            updatedList[index] = { ...updatedList[index], serialNumber: updatedSerialNumber };
            return updatedList;
        });

        focusElementRef.current?.focus();
    }

    function submitSerialNumbers(): void {
        if (updateParentSerialNumbers) {
            updateParentSerialNumbers(scannedSerialNumbers);
        }
        resetScannerState();
        setDialogOpen(false);
    }

    function resetScannerState(): void {
        setScannedSerialNumbers([]);
    }
}