import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { IIntervallPosition } from '../../Contexts/IntervallContext/IntervallProvider'
import { IProductDTO, useProducts } from '../../Contexts/ProductContext/ProductProvider'
import useAuthConnection from '../../Hooks/useAuthConnection'
import { IServiceDTO } from '../../Interfaces/Service'
import Android12Switch from '../Buttons/Switch/Android12Switch'
import { InputFieldErrorMessages } from '../InputField/InputFieldRefactored'
import SearchableSelect from '../InputField/SearchableSelect'
import FormRow from './FormUtils/FormRow'

interface props {
    object: IIntervallPosition,
    setObject: Dispatch<SetStateAction<IIntervallPosition>>,
    product?: IProductDTO,
    setProduct?: Dispatch<SetStateAction<IProductDTO | undefined>>,
    error?: any,
    setError?: Dispatch<SetStateAction<any>>
}

const EditCreateIntervallPositionForm = ({ object, setObject, product, setProduct, error, setError }: props) => {

    const [service, setService] = useState<IServiceDTO | undefined>(undefined);


    const { products, updateProduct } = useProducts();

    const connection = useAuthConnection();

    useEffect(() => {
        if (setProduct && object.productId !== undefined && object.productId > 0) {
            setProduct(products.find(temp => temp.id === object.productId));
        }
    }, [object])

    useEffect(() => {
        if (object.hasDiscount === true) {
            setObject((old) => ({ ...old, discountType: false }));
        }
    }, [object.hasDiscount])

    function diff_hours(dt2: Date, dt1: Date) {

        var diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= (60 * 60);
        return Math.abs(Math.ceil(diff / 0.25) * 0.25);

    }

    const productToBillingPosition = (val: number) => {
        if (val > 0 && products) {
            let tempObj: IProductDTO | undefined = products.find(temp => temp.id === val);
            if (setProduct && tempObj) {
                setProduct(tempObj);
            }
            setObject({ ...object, title: tempObj?.title ?? "", description: tempObj?.description ?? "", productId: tempObj?.id ?? 0, netto: tempObj?.salesPrice ?? 0, quantity: 1 })
        }
    }

    return (
        <form className="form">
            {(products && products!.length > 0 && (service === undefined || product !== undefined) || (products && object.productId !== undefined && object.productId > 0)) && <div className='form-row-label'>
                <span className="label">
                    Produkt:
                </span>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <SearchableSelect
                        width="100%"
                        titleKey='title'
                        valueKey='id'
                        options={products}
                        value={object.productId}
                        setValue={(val: any) => productToBillingPosition(val)}
                        placeholder='Produkt auswählen'
                    />
                </div>
            </div>}

            <div className='form-row-label'>
                <FormRow
                    label='Bezeichnung'
                    type='text'
                    value={object?.title}
                    onChange={(title) => setObject((old) => ({ ...old, title }))}
                    placeholder="Titel..."
                    error={error.titleError}
                    errorMessage={InputFieldErrorMessages.TitleError}
                />
            </div>
            <div className='form-row-label'>
                <FormRow
                    label='Beschreibung'
                    type='multiline'
                    value={object?.description}
                    onChange={(description) => setObject((old) => ({ ...old, description }))}
                    placeholder="Beschreibung..."
                />
            </div>
            <div className='form-row-label'>
                <FormRow
                    label='Netto'
                    type='number'
                    step='.01'
                    value={object?.netto}
                    onChange={(netto) => setObject((old) => ({ ...old, netto: parseFloat(netto) }))}
                    placeholder="Netto"
                    error={error.nettoError}
                    errorMessage={InputFieldErrorMessages.ValueRequiredError}

                />
            </div>
            <div className='form-row-label'>
                <FormRow
                    label='Steuer'
                    type='number'
                    step='.01'
                    value={object.tax}
                    onChange={(tax) => { setObject((old) => ({ ...old, tax: parseFloat(tax) })) }}
                    placeholder="Steuer"
                    error={error.taxError}
                    errorMessage={InputFieldErrorMessages.ValueRequiredError}

                />
            </div>
            <div className='form-row-label'>
                <FormRow
                    label='Stückzahl'
                    type='number'
                    step='.25'
                    value={(object.quantity && object.quantity > 0) ? object.quantity : 1}
                    onChange={(quantity) => {
                        if (parseFloat(quantity) >= 0) {
                            setObject((old) => ({ ...old, quantity: parseFloat(quantity) }))
                        }
                    }}
                    placeholder="Stückzahl"
                    error={error.quantityError}
                    errorMessage={InputFieldErrorMessages.ValueRequiredError}
                />
                {product?.count !== undefined && product.count !== null && (object?.quantity ?? 0) > product?.count && <span className="label" style={{ color: "#FF9494" }}>
                    Es sind nur {product?.count} Stück verfügbar.
                </span>}
            </div>
            <div className='form-row noselect'>
                <span style={{ fontSize: "14px" }}>Rabatt</span>
                <span>
                    <Android12Switch color="secondary" checked={object?.hasDiscount === undefined ? false : object.hasDiscount} onChange={(e, val) => setObject((oldVal: IIntervallPosition) => ({ ...oldVal, hasDiscount: val }))} />
                </span>
            </div>
            {object?.hasDiscount === true &&
                <>
                    <div className='form-row noselect'>
                        <span style={{ fontSize: "14px" }}>Prozentual</span>
                        <Android12Switch color="secondary" checked={object?.discountType === undefined ? false : object.discountType} onChange={(e, val) => setObject((oldVal: IIntervallPosition) => ({ ...oldVal, discountType: val ?? false }))} />
                        <span style={{ fontSize: "14px" }}>Betrag</span>
                    </div>
                    <FormRow
                        type='number'
                        step='.1'
                        value={object.discount}
                        onChange={(discount) => setObject((old) => ({ ...old, discount: parseFloat(discount) }))}
                        placeholder="Rabatt in %"
                        label="Rabatt"
                        error={error.discountError}
                        errorMessage='Bitte geben Sie einen Betrag für den Rabatt an'
                    />
                </>
            }
        </form>
    )
}

export default EditCreateIntervallPositionForm