import axios from 'axios';

export let baseConfig = {
    //baseURL: "https://localhost:44355",
    //baseURL: "http://192.168.188.174:37180"
    baseURL: "https://api.ecockpit.online",
    //baseURL: "http://192.168.188.163:45455",
}

if (process.env!.NODE_ENV === "production") {
    //on production build use api
    baseConfig.baseURL = "https://api.ecockpit.online";
} else if (process.env!.NODE_ENV === "development") {
    //on development build e.g. npm run start use local api
    baseConfig.baseURL = "http://localhost:37777";
} else {
    baseConfig.baseURL = "http://localhost:37777";
}

export const baseConnection = axios.create({
    ...baseConfig,
    validateStatus(status) {
        console.log("status", status)
        return status >= 200 && status < 300;
    },
})

export const authConnection = axios.create({
    ...baseConfig,
    validateStatus: function (status) {
        return status >= 200 && status < 300;
    },
})

export function errorHandler(error: any, x: any, enqueueSnackbar: any, closeSnackbar: any) {
    closeSnackbar(x);

    // Error
    enqueueSnackbar(error.response.data, { variant: "error" })
}