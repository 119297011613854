import {
  Avatar,
  Button,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import { useCompanyData } from "../../Contexts/CompanyDataContext/CompanyDataProvider";
import { card } from "../../Interfaces/Card";
import { stringToColour } from "../../Navigation/Navigation";
import Card from "../Card/Card";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import styles from "../ProfilePictureCard/ProfilePictureCard.module.css";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import CTAButton from "../Buttons/CTAButton/CTAButton";
import { useDebounceEffect } from "../Dialogs/ProfilePictureDialog/useDebounceEffect";
import { canvasPreview } from "../Dialogs/ProfilePictureDialog/canvasPreview";
import useAuthConnection from "../../Hooks/useAuthConnection";
import { useSnackBar } from "../../Hooks/useSnackBar";
import ButtonControl from "../Buttons/ButtonControl/ButtonControl";
import {
  AccountBox,
  AccountCircle,
  Rectangle,
  Square,
  Storefront,
} from "@mui/icons-material";
import { useGetMailCountQuery } from "../../redux/apiSlices/Email";

interface IPackageLimitsCard extends card {}

const PackageLimitsCard = ({ height, width, order }: IPackageLimitsCard) => {
  const { data, error, isLoading } = useGetMailCountQuery(undefined, {
    pollingInterval: 60000
  });

  return (
    <Card title={"Ihre Freieinheiten"} style={{ height, width, order }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0px",
          marginTop: "1em",
        }}
      >
        <span style={{ fontWeight: "600" }}>E-Mail Freieinheiten</span>
        <div style={{ paddingLeft: "1em", paddingRight: "1em" }}>
          <LinearProgress
            style={{ height: "0.75em" }}
            variant="determinate"
            color="success"
            value={(data?.sent! / data?.count!) * 100}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            paddingLeft: "1em",
            paddingRight: "1em",
          }}
        >
          <span style={{ fontWeight: "600" }}>Versendet</span>
          <span style={{ fontWeight: "600" }}>Offen</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            paddingLeft: "1em",
            paddingRight: "1em",
          }}
        >
          <span style={{ fontWeight: "600" }}>{data?.sent}</span>
          <span style={{ fontWeight: "600" }}>
            {data?.count! - data?.sent!}
          </span>
        </div>
      </div>
    </Card>
  );
};

export default PackageLimitsCard;
