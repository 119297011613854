
import CreateBill from './CreateBill'


const CreateOffer = () => {

  return (
    <>
      <CreateBill type='Offer' />
    </>
  )
}

export default CreateOffer