import { createApi } from "@reduxjs/toolkit/query/react";
import { ITravel, ITravelDTO } from "../../Interfaces/Travel";
import { baseAuthQuery } from "../baseAuthQuery";
import { ServiceApi } from "./Service";

export const TravelApi = createApi({
  reducerPath: "TravelApi",
  baseQuery: baseAuthQuery,
  tagTypes: ["Travel"],
  endpoints: (builder) => ({
    postTravel: builder.mutation({
      query: (data: ITravelDTO) => ({
        url: "/Travel",
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Travel" }],
    }),
    editTravel: builder.mutation<ITravel, ITravel>({
      query: (data: ITravel) => ({
        url: "/Travel",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Travel", id: arg.id }],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(ServiceApi.util.invalidateTags([{ type: "Service" }]));
      },
    }),
    deleteTravel: builder.mutation({
      query: (data: ITravel) => ({
        url: "/Travel",
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Travel" }],
    }),
    getAllTravels: builder.query<ITravel[], void>({
      query: () => ({
        url: "/travel",
      }),
      providesTags: (result) =>
        result && result.length > 0
          ? result.map(({ id }) => ({ type: "Travel", id }))
          : [{ type: "Travel" }],
    }),
  }),
});

export const {
  usePostTravelMutation,
  useEditTravelMutation,
  useDeleteTravelMutation,
  useGetAllTravelsQuery,
} = TravelApi;
