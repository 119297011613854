import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { TravelApi } from './apiSlices/Travel'
import { CalendarApi } from './apiSlices/Calendar'
import { BillPrefixApi } from './apiSlices/BillPrefix'
import { ProjectApi } from './apiSlices/Project'
import { TextModuleApi } from './apiSlices/TextModule'
import { ServiceApi } from './apiSlices/Service'
import { MailApi } from './apiSlices/Email'

const rootReducer = combineReducers({
    [TravelApi.reducerPath]: TravelApi.reducer,
    [CalendarApi.reducerPath]: CalendarApi.reducer,
    [BillPrefixApi.reducerPath]: BillPrefixApi.reducer,
    [ProjectApi.reducerPath]: ProjectApi.reducer,
    [TextModuleApi.reducerPath]: TextModuleApi.reducer,
    [ServiceApi.reducerPath]: ServiceApi.reducer,
    [MailApi.reducerPath]: MailApi.reducer
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            TravelApi.middleware,
            CalendarApi.middleware,
            BillPrefixApi.middleware,
            ProjectApi.middleware,
            TextModuleApi.middleware,
            MailApi.middleware,
            ServiceApi.middleware
        ),
});

// Infer the `RootState` and `AppDispatch` types from the store
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
