import { CalendarMonth, DateRange } from '@mui/icons-material'
import { Dispatch, SetStateAction, useState } from 'react'
import { IStaff, useStaff } from '../../Contexts/StaffContext/StaffProvider'
import { useTimeTracking } from '../../Contexts/TimeTrackingContext/TimeTrackingProvider'
import { card } from '../../Interfaces/Card'
import ButtonControl from '../Buttons/ButtonControl/ButtonControl'
import Card from '../Card/Card'
import AdminPersonalCurrentMonthRow from './AdminPersonalCurrentMonthRow'

//wir brauchen in zusätzliches Interface für die ClickedUsers
interface iAdminCurrentMonthCard extends card {
  setClickedUsers: Dispatch<SetStateAction<IStaff[]>>
}

function AdminCurrentMonthCard({ width, height, order, setClickedUsers }: iAdminCurrentMonthCard) {

  const { durationForAll } = useTimeTracking();
  const { staff } = useStaff();

  const [mode, setMode] = useState<"month" | "week">("week");

  const buttons = [
    {
      body: (active: boolean) => active ? <span className='centered' style={{ gap: "5px" }}><DateRange fontSize='small' />Woche</span> : <DateRange fontSize='small' />,
      value: "week"
    },
    {
      body: (active: boolean) => active ? <span className='centered' style={{ gap: "5px" }}><CalendarMonth fontSize='small' />Monat</span> : <CalendarMonth fontSize='small' />,
      value: "month"
    }
  ]

  return (
    <Card
      style={{ height, width, order }}
      title="Stunden intern/extern"
      disableBodyMargin
      disableScroll
      right={() => <ButtonControl setValue={(val) => setMode(val)} value={mode} buttons={buttons} />}
    >
      <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
        {staff.map((element: IStaff) => {
          if (element.active) {
            const durationInMonth = durationForAll?.inMonth.find(temp => temp.userId === element.id)?.duration;
            const durationInWeek = durationForAll?.inWeek.find(temp => temp.userId === element.id)?.duration;
            const durationInMonthService = durationForAll?.inMonth.find(temp => temp.userId === element.id)?.serviceDuration;
            const durationInWeekService = durationForAll?.inWeek.find(temp => temp.userId === element.id)?.serviceDuration;
            const hoursInMonth = (durationInMonth ?? 0) / 60;
            const hoursInWeek = (durationInWeek ?? 0) / 60;
            const hoursInMonthService = (durationInMonthService ?? 0) / 60;
            const hoursInWeekService = (durationInWeekService ?? 0) / 60;

            return (
              <>
                <AdminPersonalCurrentMonthRow 
                key={element.id}
                staff={element} 
                serviceHours={mode === "month" ? hoursInMonthService : hoursInWeekService} 
                workingHours={mode === "month" ? hoursInMonth : hoursInWeek}
                setClickedUsers={setClickedUsers}></AdminPersonalCurrentMonthRow>
              </>
            )
          }
        })}
      </div>
    </Card>
  )
}

export default AdminCurrentMonthCard