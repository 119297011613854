import { useSnackbar } from "notistack";
import { useAddServiceMutation, useBillServicesMutation, useDeleteServiceMutation, useGetAllServicesQuery, useStartServiceToTaskMutation, useStopServiceToTaskMutation, useUpdateServiceMutation } from "../apiSlices/Service"
import { IServiceDTO } from "../../Interfaces/Service";
import { RTKQueryErrorHandler } from "./utils";
import { ITaskDTO } from "../../Interfaces/Task";
import { useUser } from "../../Contexts/UserContext/UserContext";

export const useHandleAddService = () => {
    const [addService, { isLoading, isError, error }] = useAddServiceMutation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleAddService = async (service: IServiceDTO) => {
        let x = enqueueSnackbar("Leistung wird erstellt", { variant: "default" });

        try {
            const result = await addService(service).unwrap();
            closeSnackbar(x);
            enqueueSnackbar("Leistung erfolgreich erstellt", { variant: "success" });
            return result;
        } catch (err) {
            closeSnackbar(x);
            const errorMessage = RTKQueryErrorHandler(err);
            enqueueSnackbar(errorMessage, { variant: "error" });
        }
    }

    return { handleAddService, isLoading }
}

export const useHandleUpdateService = () => {
    const [updateService, { isLoading, isError, error }] = useUpdateServiceMutation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleUpdateService = async (service: IServiceDTO) => {
        let x = enqueueSnackbar("Leistung wird aktualisiert", { variant: "default" });

        try {
            const result = await updateService(service).unwrap();
            closeSnackbar(x);
            enqueueSnackbar("Leistung erfolgreich aktualisiert", { variant: "success" });
            return result;
        } catch (err) {
            closeSnackbar(x);
            const errorMessage = RTKQueryErrorHandler(err);
            enqueueSnackbar(errorMessage, { variant: "error" });
        }
    }

    return { handleUpdateService, isLoading }
}

export const useHandleDeleteService = () => {
    const [deleteService, { isLoading, isError, error }] = useDeleteServiceMutation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleDeleteService = async (service: IServiceDTO) => {
        let x = enqueueSnackbar("Leistung wird gelöscht", { variant: "default" });

        try {
            const result = await deleteService(service).unwrap();
            closeSnackbar(x);
            enqueueSnackbar("Leistung erfolgreich gelöscht", { variant: "success" });
            return result;
        } catch (err) {
            closeSnackbar(x);
            const errorMessage = RTKQueryErrorHandler(err);
            enqueueSnackbar(errorMessage, { variant: "error" });
        }
    }

    return { handleDeleteService, isLoading }
}

export const useHandleStartServiceToTask = () => {
    const [startServiceToTask, { isLoading, isError, error }] = useStartServiceToTaskMutation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { setUserWorkingOnTask } = useUser();

    const handleStartServiceToTask = async (task: ITaskDTO) => {
        let x = enqueueSnackbar("Leistung wird gestartet", { variant: "default" });

        try {
            const result = await startServiceToTask(task).unwrap();
            closeSnackbar(x);
            if (setUserWorkingOnTask) setUserWorkingOnTask(task.id);
            enqueueSnackbar("Leistung gestartet", { variant: "success" });
            return result;
        } catch (err) {
            closeSnackbar(x);
            const errorMessage = RTKQueryErrorHandler(err);
            enqueueSnackbar(errorMessage, { variant: "error" });
        }
    }

    return { handleStartServiceToTask, isLoading }
}

export const useHandleStopServiceToTask = () => {
    const [stopServiceToTask, { isLoading, isError, error }] = useStopServiceToTaskMutation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { setUserWorkingOnTask } = useUser();

    const handleStopServiceToTask = async (task: ITaskDTO) => {
        let x = enqueueSnackbar("Leistung wird gestoppt", { variant: "default" });

        try {
            const result = await stopServiceToTask(task).unwrap();
            closeSnackbar(x);
            if (setUserWorkingOnTask) setUserWorkingOnTask(undefined);
            enqueueSnackbar("Leistung gestoppt", { variant: "success" });
            return result;
        } catch (err) {
            closeSnackbar(x);
            const errorMessage = RTKQueryErrorHandler(err);
            enqueueSnackbar(errorMessage, { variant: "error" });
        }
    }

    return { handleStopServiceToTask, isLoading }
}

export const useHandleSetServicesBilled = () => {

    const { data: services } = useGetAllServicesQuery();
    const [billServices] = useBillServicesMutation();

    const handleSetServicesBilled = async (serviceIds: number[]) => {
        console.log("given service ids", serviceIds)
        if (serviceIds.length === 0) return;

        if (!services || services.length === 0) return;

        const servicesToBill = services.filter((service) => serviceIds.includes(service.id));

        let result = await billServices(servicesToBill).unwrap();
        console.log("bill services result", result)
    }


    return {
        handleSetServicesBilled
    }
}