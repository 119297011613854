import { IconButton, Tooltip } from "@mui/material"
import { IProductDTO } from "../../Contexts/ProductContext/ProductProvider"
import { Input } from "../UI/Input"
import { Dispatch, SetStateAction } from "react"
import { Minus, Plus, Trash } from "lucide-react"

type ScannedProductRowProps = {
    serialNumber: string
    updateSerialNumber: (serial: string) => void
    removeSerialNumber: (index: number) => void
    index: number
    focusRef: React.RefObject<HTMLDivElement>
}

export default function ScannedSerialRow({ serialNumber, updateSerialNumber: setSerialNumber, removeSerialNumber, index, focusRef }: ScannedProductRowProps) {

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") { //prevent extra click on IconButtons from Barcode-Scanner input if it includes \n
            e.preventDefault();
        }
    }

    return (
        <div className="flex flex-col">
            <div className="flex flex-row items-center w-full gap-2" >
                <Input className="w-[80%] min-w-[60px] text-center" type="text" value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} />
                <Tooltip title="Seriennummer entfernen"><IconButton onKeyDown={handleKeyDown} onClick={() => removeSerialNumber(index)}><Trash color="red" /></IconButton></Tooltip>
            </div>
        </div>
    )
}