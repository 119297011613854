import { AddRounded } from '@mui/icons-material'
import BusinessIcon from '@mui/icons-material/Business'
import PersonIcon from '@mui/icons-material/Person'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { IconButton, Tooltip } from '@mui/material'
import React, { Dispatch, SetStateAction, useEffect, useState, useTransition } from 'react'
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { useModals } from '../../Contexts/DialogContext/DialogProvider'
import { useUser } from '../../Contexts/UserContext/UserContext'
import { card } from '../../Interfaces/Card'
import { IServiceDTO, serviceWithNames } from '../../Interfaces/Service'
import { serviceFormModes } from '../../Pages/Services/Services'
import { useGetAllServicesQuery } from '../../redux/apiSlices/Service'
import Card from '../Card/Card'
import SearchBar from '../SearchBar/SearchBar'
import Service from '../Service/Service'

export interface blankcard extends card {
  cta?: boolean
  ctaTitle?: string
}

interface IServiceCard extends blankcard {
  setClickedService?: Dispatch<SetStateAction<IServiceDTO>>
  setFormMode?: Dispatch<SetStateAction<serviceFormModes>>
}

function ServiceCard({ width, height, order, cta, ctaTitle, setClickedService, setFormMode }: IServiceCard) {
  const { data: services } = useGetAllServicesQuery();
  const { user } = useUser();
  const { getCompanyByID, companies } = useContacts()

  const [isAdminView, setIsAdminView] = useState(false);

  const [isPending, startTransition] = useTransition()

  //* Service-Managment
  //Speichert den Suchwert in den State -> Beim Hinzufügen einer neuen Leistung wird dieser bei Suchwert nach diesem gefiltert.
  const [searchValue, setSearchValue] = useState("")

  //Kopie der Services für das Suchen und Filtern dieser
  const [copiedServices, setCopiedServices] = useState<serviceWithNames[]>([])

  const onSearch = (val: string) => {
    setSearchValue(val)
    startTransition(() => {
      if (services) {
        setCopiedServices(() => {
          let temp = services.filter((service: serviceWithNames) => {
            if ((service.title?.toLowerCase().includes(searchValue.toLowerCase()) || service.description?.toLowerCase().includes(searchValue?.toLowerCase()) || service.clientOrganizationName?.toLowerCase().includes(searchValue.toLowerCase())) && (isAdminView === true ? true : service.employeeDatas.find(temp => temp.userId === user?.userId) !== null)) {
              return true;
            }
            return false;
          })

          return temp;
        });
      }
    })
  }

  const [searchBarAutofocus, setSearchBarAutofocus] = useState(false);

  //Regelt das Öffnen und Schließen der Expandable Section der ServiceCard
  const handleExpand = (prevExpanded: boolean, setExpanded: React.Dispatch<React.SetStateAction<boolean>>) => {
    //Beim Schließen der Suchleiste wird der Suchwert zurückgesetzt und alle Leistungen wieder angezeigt
    if (prevExpanded) {
      setSearchValue("")
      setCopiedServices(() => {
        let result = services?.map((service: serviceWithNames) => {
          service.clientOrganizationName = service.clientOrganizationId ? getCompanyByID!(service.clientOrganizationId)?.name : "";
          return service;
        })

        result = result?.filter((service: serviceWithNames) => {
          if ((service.title.toLowerCase().includes(searchValue.toLowerCase()) || service.description.toLowerCase().includes(searchValue.toLowerCase()) || service.clientOrganizationName?.toLowerCase().includes(searchValue.toLowerCase())) && (isAdminView === true ? true : service.employeeDatas.find(temp => temp.userId === user?.userId) !== null)) {
            return true;
          }
          return false;
        })

        return result ?? [];
      });
       setSearchBarAutofocus(false);
    }
    setExpanded((val) => !val)
    if (!prevExpanded) setSearchBarAutofocus(true);
  }


  useEffect(() => {
    if (!services) return

    if (searchValue != "") {
      setCopiedServices(() => {
        let temp = services.filter((service: serviceWithNames) => {
          if ((service?.title?.toLowerCase().includes(searchValue?.toLowerCase()) || service?.description?.toLowerCase().includes(searchValue?.toLowerCase()) || service?.clientOrganizationName?.toLowerCase().includes(searchValue?.toLowerCase())) && (isAdminView === true ? true : service.employeeDatas.find(temp => temp.userId === user?.userId) !== null)) {
            return true;
          }
          return false;
        })

        return temp;
    });
    } else {
      setCopiedServices(() => {
        let result = services.filter((service) => {
          if ((isAdminView === true ? true : service.employeeDatas.filter(temp => temp.userId === user?.userId).length > 0)) {
            return true;
          }
          return false;
        })
        result = result.filter((service: serviceWithNames) => {
          if ((service?.title?.toLowerCase().includes(searchValue?.toLowerCase()) || service?.description?.toLowerCase().includes(searchValue?.toLowerCase()) || service?.clientOrganizationName?.toLowerCase().includes(searchValue?.toLowerCase())) && (isAdminView === true ? true : service.employeeDatas.find(temp => temp.userId === user?.userId) !== null)) {
            return true;
          }
          return false;
        })

        return result;
    });
    }

  }, [services, companies, isAdminView])

  //* Modal-Management
  const [open, setOpen] = useState(false)

  const { changeVisibility } = useModals()

  return (
    <Card
      expandedElement={<SearchBar autofocus={searchBarAutofocus} value={searchValue} onChangeHandler={(searchValue) => onSearch(searchValue)} />}
      title='Leistungen'
      cta={cta}
      ctaTitle={ctaTitle}
      ctaFunction={() => changeVisibility!("createService", true)}
      buttonIcon={<AddRounded fontSize='small' />}
      style={{ width, height, order }}
      right={({ expanded, setExpanded }) =>
        <div className='flex'>
          {(user?.organizationRoleName === "Admin" || user?.organizationRoleName === "Owner") &&
            <div className='rounded-full flex gap-1'>
              <div className={`rounded-full border ${!isAdminView && "bg-brand-light"}`}>
                <Tooltip title="Meine Leistungen">
                  <IconButton onClick={() => setIsAdminView(false)}><PersonIcon /></IconButton>
                </Tooltip>
              </div>
              <div className={`rounded-full border ${isAdminView && "bg-brand-light"}`}>
                <Tooltip title="Alle Leistungen">
                  <IconButton onClick={() => setIsAdminView(true)}><BusinessIcon /></IconButton>
                </Tooltip>
              </div>
            </div>
          }
          <Tooltip title="Leistungen suchen">
            <IconButton style={{ backgroundColor: expanded ? "var(--brand)" : undefined, color: expanded ? "white" : undefined }} onClick={() => handleExpand(expanded, setExpanded)}><SearchRoundedIcon /></IconButton>
          </Tooltip>
        </div>
      }

    >
      <div className='flex column' style={{ gap: "5px" }}>
        {
          copiedServices.length > 0 ?
            copiedServices.map((service, index) => {
              return <Service isAdminView={isAdminView} backgroundColor={index % 2 == 1 ? "var(--ultra-light)" : undefined} service={service} key={index} setClickedService={setClickedService} setFormMode={setFormMode} />
            })
            :
            "Keine Leistungen gefunden"
        }
      </div>
    </Card>
  )
}

export default ServiceCard