import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { useServices } from '../../../Contexts/ServiceContext/ServiceContext'
import { useTasks } from '../../../Contexts/TaskContext/TaskContext'
import { useUser } from '../../../Contexts/UserContext/UserContext'
import { IServiceDTO } from '../../../Interfaces/Service'
import { ITask } from '../../../Interfaces/Task'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import CreateServiceForm from '../../Forms/CreateServiceForm'
import { dialog } from '../CreateServiceDialog/CreateServiceDialog'
import { useHandleAddService } from '../../../redux/requestHandlers/Service'

export interface dialogWithMultipleProps<T> extends dialog {
  object: T[]
  mode?: string
}

const CreateMultipleServicesDialog = ({ object, mode, open, onClose }: dialogWithMultipleProps<ITask>) => {
  const { defaultTask, selectedTasks, finishTask } = useTasks()
  const { defaultService } = useServices()
  const { handleAddService: addService, isLoading: isAddServiceLoading } = useHandleAddService();

  const { user } = useUser()

  const { changeVisibility } = useModals()

  const [tasksToConvert, setTasksToConvert] = useState<ITask[]>(object ?? selectedTasks)
  const [index, setIndex] = useState(0)

  const convertTaskToService = (taskToConvert: ITask) => {

    const { title, description, note, userIds, organizationId, customerOrganizationId } = taskToConvert ?? {}

    return {
      id: 0,
      title,
      description,
      note,
      organizationId,
      employeeDatas: userIds?.map((userId) => ({
        //TODO Wirkliche Daten der User verwenden
        id: 0,
        userId,
        from: dayjs(),
        to: dayjs(),
        hourlyRate: 20,
        serviceId: 0,
        organizationId: organizationId
      })),
      billable: true,
      billed: false,
      clientOrganizationId: customerOrganizationId,
      intern: organizationId == user?.organizationId,
      type: 1
    } as IServiceDTO
  }

  const [serviceToSubmit, setServiceToSubmit] = useState<IServiceDTO>(convertTaskToService(tasksToConvert[index]) ?? defaultService)

  const handleSubmitAndFinishTask = async () => {
    if (validateInput(serviceToSubmit)) {
      handleSubmit();
      if (finishTask) {
        finishTask(tasksToConvert[index])
      }
    }
  }

  const handleSubmit = async () => {
    if (validateInput(serviceToSubmit)) {
      addService!(serviceToSubmit);
      setIndex((old) => {
        if (old + 1 <= tasksToConvert.length - 1) {
          setServiceToSubmit(convertTaskToService(tasksToConvert[old + 1]))
          return old + 1
        } else {
          return old
        }
      })
      if (index + 1 >= tasksToConvert.length) {
        onClose ? onClose() : changeVisibility!("transformIntoService", false)
      }
    }
  }

  useEffect(() => {
    setTasksToConvert(object ?? selectedTasks)
    setIndex(0)
    setServiceToSubmit((object && object[0]) ? convertTaskToService(object[0]) : defaultService!)
  }, [object])

  //form error handling variables
  const [employeeError, setEmployeeError] = useState(false);
  const [customerError, setCustomerError] = useState(false);
  const [titleError, setTtitleError] = useState(false);
  const [dateStartError, setDateStartError] = useState(false);

  function validateInput(service: IServiceDTO) {
    if (service.employeeDatas.length === 0) {
      setEmployeeError(true);
    } else {
      setEmployeeError(false);
    }

    if (!service.clientOrganizationId || service.clientOrganizationId <= 0) {
      setCustomerError(true);
    } else {
      setCustomerError(false);
    }

    if (service.title.length === 0) {
      setTtitleError(true);
    } else {
      setTtitleError(false);
    }

    const from = dayjs(service.employeeDatas[0].from)
    const to = dayjs(service.employeeDatas[0].to)

    if (from.isAfter(to)) {
      setDateStartError(true);
    } else {
      setDateStartError(false);
    }

    if (
      service.employeeDatas.length === 0 ||
      !service.clientOrganizationId ||
      service.clientOrganizationId <= 0 ||
      service.title.length === 0 ||
      from.isAfter(to)
    ) {
      return false;
    }

    return true;
  }

  return (
    <Modal
      title={tasksToConvert.length > 1 ? "Leistungen erstellen" : "Leistung erstellen"}
      open={open}
      onClose={onClose ? () => onClose() : () => changeVisibility!("transformIntoService", false)}
    >
      {tasksToConvert.length > 1 && (<div style={{ marginBottom: "20px", width: "fit-content", padding: "5px 10px", borderRadius: "10px", backgroundColor: "var(--brand)" }}>
        <span style={{ color: "white", fontWeight: "bold" }}>{(index + 1) + "/" + tasksToConvert?.length}</span>
      </div>)}
      <CreateServiceForm
        object={serviceToSubmit}
        setObject={setServiceToSubmit}
        errorVariables={{ employeeError, customerError, titleError, dateStartError }}
      />

      <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
        {(tasksToConvert?.length ?? 0) > 0 &&
          <CTAButton
            title={'Speichern und Task abschließen'}
            onClickHandler={() => handleSubmitAndFinishTask()}
          />
        }
        <CTAButton
          title={index == tasksToConvert.length - 1 ? 'Speichern' : "Speichern und Weiter"}
          onClickHandler={() => handleSubmit()}
        />
      </div>
    </Modal>
  )
}

export default CreateMultipleServicesDialog