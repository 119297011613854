
export default function ServiceReducer(state: any, action: any) {
    switch (action.type) {
        case "SET_SERVICES":
            return {
                ...state,
                services: action.payload
            }
        default:
            return {
                ...state
            }
    }
}