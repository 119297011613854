import { AxiosResponse } from 'axios'
import React, { ReactNode, useContext, useEffect, useReducer } from 'react'
import { createContext } from 'react'
import useAuthConnection from '../../Hooks/useAuthConnection'
import { useSnackBar } from '../../Hooks/useSnackBar'
import EMailReducer from './EMailReducer'
import { errorHandler } from '../../Connection/BaseConnection'
import { autoHideDurationDefault } from '../../Global/Variables'

export interface IEMailRequest {
  toEmail: Array<string>,
  attachments: Array<any>,
  subject: string,
  body: string
}

export interface IEMailDTO {

}


interface IEMailContext {
  sendMissedCallMail?: (text: string, organizationId?: number, personId?: number) => void,
  sendMail?: (mailRequest: any) => void
}

const EMailContext = createContext<IEMailContext>({
})

const EMailProvider = ({ children }: { children: ReactNode }) => {
  const connection = useAuthConnection();
  const { enqueueSnackbar, closeSnackbar } = useSnackBar();

  const [state, dispatch] = useReducer(EMailReducer, {
  })

  const sendMissedCallMail = (text: string, organizationId?: number, personId?: number) => {
    let x = enqueueSnackbar("Rückrufmail wird versandt", { variant: "default", autoHideDuration: autoHideDurationDefault })

    connection.post("/email/sendMissedCallMail", null,
      {
        params: {
          text,
          organizationId,
          personId
        }
      }
    )
      .then((res: AxiosResponse) => {
        closeSnackbar(x);
        enqueueSnackbar("Rückrufmail erfolgreich erstellt", { variant: "success" })
      })
      .catch((error: any) => {
        errorHandler(error, x, enqueueSnackbar, closeSnackbar)
      })
  }


  return (
    <EMailContext.Provider
      value={{
        sendMissedCallMail
      }}
    >
      {children}
    </EMailContext.Provider>
  )
}

export default EMailProvider

export const useEMail = () => useContext(EMailContext)