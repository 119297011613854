import { CircularProgress } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { useEmployees } from '../../Contexts/EmployeeContext/EmployeeProvider'
import { ITravel, ITravelDTO } from '../../Interfaces/Travel'
import { useGetAllServicesQuery } from '../../redux/apiSlices/Service'
import { useEditTravelMutation, usePostTravelMutation } from '../../redux/apiSlices/Travel'
import CTAButton from '../Buttons/CTAButton/CTAButton'
import { form } from './CreateTaskForm'
import Form from './FormUtils/Form'
import FormRow from './FormUtils/FormRow'

type TravelFormModes = "create" | "edit";

const TravelForm = ({ object: travel, setObject: setTravel, mode, submitFunction, loading }: form<ITravel, TravelFormModes>) => {

    const { companies } = useContacts();
    const { data: services, isLoading: isServicesLoading } = useGetAllServicesQuery();
    const { employees } = useEmployees();

    const [travelDuration, setTravelDuration] = useState<Dayjs | null>(null);
    const [userSelectError, setUserSelectError] = useState(false);

    const [postTravel] = usePostTravelMutation();
    const [editTravel] = useEditTravelMutation();

    //react hook form variables
    console.log("travel before default value set", travel)
    const { register, formState: { errors }, handleSubmit, setValue } = useForm<ITravel | ITravelDTO>({ defaultValues: travel });

    useEffect(() => {
        setValue("startDestination", travel.startDestination);
        setValue("endDestination", travel.endDestination);
        setValue("distance", travel.distance);
    }, [travel.id])

    useEffect(() => {
        if (travel.timeStart != null && travel.timeEnd != null) {
            setTravelDuration(calculateDuration(travel.timeStart, travel.timeEnd));
        }
    }, [travel.timeStart, travel.timeEnd])

    const onFormSubmit = () => {
        setUserSelectError(userSelectInputHasError(travel.userIds));

        if (!userSelectInputHasError(travel.userIds)) {
            if (submitFunction) {
                submitFunction();
            } else {
                if (mode === "edit") {
                    editTravel(travel as ITravel);
                }
                else {
                    postTravel(travel as ITravelDTO);
                }
            }
        }
    }

    return (
        <Form
            style={{ marginBottom: "20px" }}
        >
            <FormRow
                label="Mitarbeiter*innen*"
                value={travel.userIds}
                placeholder="Mitarbeiter*innen auswählen"
                type="select"
                selectOptions={{
                    titleKey: "username",
                    valueKey: "id",
                    options: employees,
                    multiple: true
                }}
                onChange={(val) => setTravel((old) => ({ ...old, userIds: val }))}
                error={userSelectError}
                errorMessage={"Bitte mind. eine*n Mitarbeiter*in auswählen"}
            />
            <FormRow
                label='Datum der Fahrt'
                type='date'
                dateOptions={{
                    type: "Date"
                }}
                onChange={(val) => setTravel((old) => ({ ...old, date: val }))}
                value={travel.date}
            />
            <FormRow
                label="Kunde"
                value={travel.customerOrganizationId}
                type="select"
                selectOptions={{
                    titleKey: "name",
                    valueKey: "id",
                    options: companies
                }}
                onChange={(val) => setTravel((old) => ({ ...old, customerOrganizationId: val }))}
            />
            <div className='flex flex-row gap-4 justify'>
                <FormRow
                    label="Startort*"
                    placeholder='Startort'
                    value={travel.startDestination}
                    type="text"
                    onChange={(val) => setTravel((old) => ({ ...old, startDestination: val }))}
                    register={register("startDestination", { required: "Bitte einen Startort angeben" })}
                    error={!!errors.startDestination}
                    errorMessage={errors.startDestination?.message}
                />
                <FormRow
                    label="Zielort*"
                    placeholder='Zielort'
                    value={travel.endDestination}
                    type="text"
                    onChange={(val) => setTravel((old) => ({ ...old, endDestination: val }))}
                    register={register("endDestination", { required: "Bitte ein Ziel angeben" })}
                    error={!!errors.endDestination}
                    errorMessage={errors.endDestination?.message}
                />
                <FormRow
                    value={travel.distance}
                    label="Distanz*"
                    type="number"
                    onChange={(val) => setTravel((old) => ({ ...old, distance: parseFloat(val.toString()) }))}
                    register={register("distance", { required: "Bitte die zurückgelegte Distanz angeben", validate: (value) => { if (value <= 0) return "Bitte eine gültige Distanz angeben (>0)" } })}
                    error={!!errors.distance}
                    errorMessage={errors.distance?.message}
                />
            </div>
            <div className='flex flex-row gap-4'>
                <FormRow
                    type='date'
                    dateOptions={{
                        type: "Time"
                    }}
                    value={travel.timeStart}
                    label='Startzeit'
                    onChange={(val) => setTravel((old) => ({ ...old, timeStart: val }))}
                />
                <FormRow
                    type='date'
                    dateOptions={{
                        type: "Time"
                    }}
                    value={travel.timeEnd}
                    label='Endzeit'
                    onChange={(val) => setTravel((old) => ({ ...old, timeEnd: val }))}
                />
                <FormRow
                    type='date'
                    label="Dauer (automatisch)"
                    dateOptions={{
                        type: "Time"
                    }}
                    readonly
                    value={travelDuration}
                    onChange={() => { }}
                />
            </div>
            <FormRow
                type='switch'
                value={travel.return}
                onChange={(val) => setTravel((old) => ({ ...old, return: val }))}
                title="Rückfahrt"
            />
            <FormRow
                type="multiline"
                label="Notizen"
                placeholder='Zweck der Fahrt, Anmerkungen...'
                value={travel.note}
                onChange={(val) => setTravel((old) => ({ ...old, note: val }))}
            />
            <FormRow
                type="select"
                label="Leistung zuordnen"
                placeholder='Leistung...'
                value={travel.serviceId}
                onChange={(val) => setTravel((old) => ({ ...old, serviceId: val ?? null }))}
                selectOptions={{
                    titleKey: "title",
                    valueKey: "id",
                    options: services ?? []
                }}
            />
            <CTAButton
                title={loading ? <CircularProgress size={18} color='inherit' /> : "Speichern"}
                onClickHandler={handleSubmit(onFormSubmit)}
                disabled={loading}
            />
        </Form>
    )
}



function calculateDuration(timeStart: Dayjs, timeEnd: Dayjs): Dayjs {

    //Convert, necessary if travel is being edited
    const start = dayjs(timeStart)
    const end = dayjs(timeEnd);


    // Calculate duration in minutes (with decimal places)
    const duration = end.diff(start, 'minute');
    // Extract whole hours
    const hours = Math.floor(duration / 60);
    // Extract remaining minutes
    const minutes = Math.round(duration % 60);

    //create dayjs date from extracted hours and minutes
    const durationDate = dayjs().startOf('day').add(hours, 'hours').add(minutes, 'minutes');

    return durationDate;
}

function userSelectInputHasError(userIds: number[]) {
    if (!userIds || userIds.length <= 0) {
        return true;
    }
    return false;
}

export default TravelForm
