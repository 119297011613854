import { AddRounded } from '@mui/icons-material'
import React, { Dispatch, SetStateAction, useEffect, useState, useTransition } from 'react'
import { useServices } from '../../Contexts/ServiceContext/ServiceContext'
import Card from '../Card/Card'
import SearchBar from '../SearchBar/SearchBar'
import ServiceOverview from '../Service/ServiceOverview'
//Icons
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { IconButton, Tooltip } from '@mui/material'
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { useModals } from '../../Contexts/DialogContext/DialogProvider'
import { IStaff } from '../../Contexts/StaffContext/StaffProvider'
import { useUser } from '../../Contexts/UserContext/UserContext'
import { card } from '../../Interfaces/Card'
import { IServiceDTO, serviceWithNames } from '../../Interfaces/Service'
import { serviceFormModes } from '../../Pages/Services/Services'
import { useGetAllServicesQuery } from '../../redux/apiSlices/Service'

export interface blankcard extends card {
  cta?: boolean
  ctaTitle?: string
}

interface IServiceCard extends blankcard {
  setClickedService?: Dispatch<SetStateAction<IServiceDTO>>
  setFormMode?: Dispatch<SetStateAction<serviceFormModes>>
  isAdminView?: boolean
  clickedUsers?: IStaff[]
}

function ServiceOverviewCard({ width, height, order, cta, ctaTitle, setClickedService, setFormMode, isAdminView = false, clickedUsers }: IServiceCard) {
  const { data: services, isLoading: isServicesLoading } = useGetAllServicesQuery();
  const { user } = useUser();
  const { getCompanyByID, companies } = useContacts()


  const [isPending, startTransition] = useTransition()

  //* Service-Managment
  //Speichert den Suchwert in den State -> Beim Hinzufügen einer neuen Leistung wird dieser bei Suchwert nach diesem gefiltert.
  const [searchValue, setSearchValue] = useState("")

  //Kopie der Services für das Suchen und Filtern dieser
  const [filteredServices, setFilteredServices] = useState<serviceWithNames[]>([])

  const onSearch = (val: string) => {
    if(!services) return;
    setSearchValue(val)
    startTransition(() => {
      setFilteredServices(() => {
        let temp = services.filter((service: serviceWithNames) => {
          if ((service?.title?.toLowerCase().includes(searchValue?.toLowerCase()) || service?.description?.toLowerCase().includes(searchValue?.toLowerCase()) || service?.clientOrganizationName?.toLowerCase().includes(searchValue?.toLowerCase())) && (isAdminView === true ? true : service.employeeDatas.find(temp => temp.userId === user?.userId) !== null)) {
            return true;
          }
          return false;
        })

        return temp;
      });
    })
  }

  //Regelt das Öffnen und Schließen der Expandable Section der ServiceCard
  const handleExpand = (prevExpanded: boolean, setExpanded: React.Dispatch<React.SetStateAction<boolean>>) => {
    //Beim Schließen der Suchleiste wird der Suchwert zurückgesetzt und alle Leistungen wieder angezeigt
    if (prevExpanded) {
      setSearchValue("")
      setFilteredServices(services ?? [])
    }
    setExpanded((val) => !val)
  }


  useEffect(() => {
    if (searchValue != "" && services) {
      setFilteredServices(() => {
        let result = services.filter((service: serviceWithNames) => {
          if ((service?.title?.toLowerCase().includes(searchValue?.toLowerCase()) || service?.description?.toLowerCase().includes(searchValue?.toLowerCase()) || service?.clientOrganizationName?.toLowerCase().includes(searchValue?.toLowerCase())) && (isAdminView === true ? true : service.employeeDatas.find(temp => temp.userId === user?.userId) !== null)) {
            return true;
          }
          return false;
        })
        if (clickedUsers!.length > 0) {
          console.log("Vorher: ", result)
          //"Schöner" Syntax: clickedUsers?.forEach(user => (service.employeeDatas.map(ed => ed.userId).includes(user.id)))
          result = result.filter((service: serviceWithNames) => {
            console.log("Ausgewählter User: ", clickedUsers)
            let tempClickedUsers = clickedUsers?.filter(user => {
              console.log("ID-Vergleich: ", user.id, service.employeeDatas.map(ed => ed.userId), service.employeeDatas.map(ed => ed.userId).includes(user.id))
              if (service.employeeDatas.map(ed => ed.userId).includes(user.id)) {
                return true;
              }
              else {
                return false;
              }
            })
            return tempClickedUsers!.length > 0
          })
          console.log("Nachher: ", result, clickedUsers)
        }
        return result;
      });
    }
    else {
      setFilteredServices(() => {
        if(!services) return [];
        let result = services.filter((service) => {
          if ((isAdminView === true ? true : service.employeeDatas.filter(temp => temp.userId === user?.userId).length > 0)) {
            return true;
          }
          return false;
        })

        if (clickedUsers!.length > 0) {
          console.log("Vorher: ", result)
          //"Schöner" Syntax: clickedUsers?.forEach(user => (service.employeeDatas.map(ed => ed.userId).includes(user.id)))
          result = result.filter((service: serviceWithNames) => {
            let tempClickedUsers = clickedUsers?.filter(user => {
              if (service.employeeDatas.map(ed => ed.userId).includes(user.id)) {
                return true;
              }
              else {
                return false;
              }
            })
            return tempClickedUsers!.length > 0
          })
        }
        console.log("Nachher: ", result, clickedUsers)
        return result;
      });
    }

  }, [services, companies, isAdminView, clickedUsers])

  //* Modal-Management
  const [open, setOpen] = useState(false)

  const { changeVisibility } = useModals()

  return (
    <Card
      expandedElement={<SearchBar value={searchValue} onChangeHandler={(searchValue) => onSearch(searchValue)} />}
      title='Alle Leistungen'
      cta={cta}
      ctaTitle={ctaTitle}
      ctaFunction={() => changeVisibility!("createService", true)}
      buttonIcon={<AddRounded fontSize='small' />}
      style={{ width, height, order }}
      right={({ expanded, setExpanded }) =>
        <Tooltip title="Leistungen suchen">
          <IconButton style={{ backgroundColor: expanded ? "var(--brand)" : undefined, color: expanded ? "white" : undefined }} onClick={() => handleExpand(expanded, setExpanded)}><SearchRoundedIcon /></IconButton>
        </Tooltip>}
    >
      <div className='flex column' style={{ gap: "5px" }}>
        {
          filteredServices.length > 0 ?
            filteredServices.map((service, index) => {
              return <ServiceOverview isAdminView={isAdminView} backgroundColor={index % 2 == 1 ? "var(--ultra-light)" : undefined}
                service={service} key={index} setClickedService={setClickedService} setFormMode={setFormMode} />
            })
            :
            "Keine Leistungen gefunden"
        }
      </div>
    </Card>
  )
}

export default ServiceOverviewCard