import React, { useEffect, useRef, useState } from 'react'
import CTAButton from '../Buttons/CTAButton/CTAButton'
import FormRow from '../Forms/FormUtils/FormRow'

import { Delete } from '@mui/icons-material'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'
import { useBills } from '../../Contexts/BillContext/BillProvider'
import { useCompanyData } from '../../Contexts/CompanyDataContext/CompanyDataProvider'
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { useProducts } from '../../Contexts/ProductContext/ProductProvider'

import { ITask } from '../../Interfaces/Task'
import { IDeliveryNoteDTO, IDeliveryNotePosition, IDeliveryNotePositionDTO, IDeliveryNotePositionSerialNumber } from '../../Pages/Services/LieferscheinErstellen'
import { useGetAllProjectsQuery } from '../../redux/apiSlices/Project'
import { defaultProjectDTO, IDeliveryNote, useTasks } from '../../Contexts/TaskContext/TaskContext'
import { useScannedProductsForDeliveryNote } from './useScannedProductsForDeliveryNote'
import Card from '../Card/Card'
import { convertDeliveryNotePositionsToProducts } from './utils'
import ProductScanner from '../Product/ProductScanner'
import SerialNumberScanner from '../Product/SerialNumberScanner'

let customerListId = 0;
function getCustomerListId() {
    return customerListId++;
}

interface IDeliveryNoteFormCard {
    deliveryNoteDTO: IDeliveryNoteDTO
    setDeliveryNoteDTO: React.Dispatch<React.SetStateAction<IDeliveryNoteDTO>>
    setDocument: React.Dispatch<any>
    id?: number
    taskId?: number
    projectId?: number
}

enum CustomerOptionTypes {
    Person = 0,
    Organization = 1
}

interface ICustomerOption {
    id: number
    customerId: number
    name: string
    type: CustomerOptionTypes
}

function DeliveryNoteFormCard({ deliveryNoteDTO, setDeliveryNoteDTO, setDocument }: IDeliveryNoteFormCard) {

    const location = useLocation();

    // Parse and extract query parameters
    const queryParams = queryString.parse(location.search);
    const id = parseInt(queryParams.id && !Array.isArray(queryParams.id) ? queryParams.id : '', 10);
    const taskId = parseInt(queryParams.taskId && !Array.isArray(queryParams.taskId) ? queryParams.taskId : '', 10);
    const projectId = parseInt(queryParams.projectId && !Array.isArray(queryParams.projectId) ? queryParams.projectId : '', 10);

    const { companyData } = useCompanyData();
    const { companies, persons } = useContacts();
    const { products } = useProducts();
    const { deliveryNotes, addDeliveryNotePosition, updateDeliveryNotePosition, deleteDeliveryNotePosition } = useTasks();
    const { data: projects } = useGetAllProjectsQuery();
    const { allTasksUnformated } = useTasks();
    const { units } = useBills();
    const signatureRef: any = useRef();

    const { addDeliveryNote, updateDeliveryNote } = useTasks();


    const { refreshDocument } = useTasks();
    const generatePdf = (deliveryNote?: IDeliveryNoteDTO) => {
        refreshDocument!(deliveryNote ?? deliveryNoteDTO)
            .then((res) => {
                setDocument(res.base64)
            })
    }
    const { setScannedProducts } = useScannedProductsForDeliveryNote(deliveryNoteDTO, setDeliveryNoteDTO, generatePdf);

    const handleSaveSignature = (cb?: any) => {
        const signatureImage = signatureRef.current.toDataURL();

        setDeliveryNoteDTO((old: any) => ({ ...old, signature: signatureImage }))

        if (!exists) {
            //@ts-ignore
            let tempTask = allTasksUnformated.find(task => task.id === parseInt(queryParams.taskId));
            let customer = tempTask?.customerOrganizationId ? companies.find(comp => comp.id === tempTask?.customerOrganizationId) : undefined;
            addDeliveryNote({ ...deliveryNoteDTO, signature: signatureImage, taskId: id })
                .then((data: IDeliveryNoteDTO) => {
                    setDocument(data.base64)
                    setDeliveryNoteDTO((old: IDeliveryNoteDTO) => ({ ...old, id: data.id, documentId: data.documentId, products: data.products, number: data.number, customerOrganization: customer, customerOrganizationId: customer?.id }))
                    cb(data.id)
                })
            setExists(true);
        } else {
            updateDeliveryNote({ ...deliveryNoteDTO, signature: signatureImage, taskId: id })
                .then((data: any) => {
                    setDeliveryNoteDTO((old: IDeliveryNoteDTO) => ({ ...old, id: data.id, documentId: data.documentId, products: data.products, positions: data.positions }))
                    setDocument(data.base64)
                })
        }

    }

    const [newProduct, setNewProduct] = useState<number>()
    const [newTask, setNewTask] = useState<number>();
    const [selectableTasks, setSelectableTasks] = useState<Array<ITask>>(allTasksUnformated.filter(t => {
        return t.customerOrganizationId === deliveryNoteDTO.customerOrganization?.id && deliveryNoteDTO.tasks?.filter((temp: any) => temp.id === t.id).length === 0
    }))

    useEffect(() => {
        setSelectableTasks(allTasksUnformated.filter(t => {
            return t.customerOrganizationId === deliveryNoteDTO.customerOrganization?.id && deliveryNoteDTO.tasks?.filter((temp: any) => temp.id === t.id).length === 0
        }))
    }, [allTasksUnformated, deliveryNoteDTO.tasks, deliveryNoteDTO.customerOrganization]);

    useEffect(() => { if (projectId) setDeliveryNoteDTO((old: IDeliveryNote) => ({ ...old, tasks: allTasksUnformated?.filter((t: ITask) => t.projectId === projectId) })) }, [deliveryNoteDTO.projectId])


    const [exists, setExists] = useState<boolean>(false);

    const [customerOptions, setCustomerOptions] = useState<ICustomerOption[]>([]);

    useEffect(() => {
        let findDeliveryNote = deliveryNotes?.find(dn => dn.id === id || dn.projectId === projectId);
        if (findDeliveryNote) {
            findDeliveryNote.products = convertDeliveryNotePositionsToProducts(findDeliveryNote.positions);

            if (findDeliveryNote.customerOrganizationId) {
                findDeliveryNote.isOrganization = true;
            } else {
                findDeliveryNote.isOrganization = false;
            }

            setExists(findDeliveryNote !== undefined)
            setDeliveryNoteDTO({ ...findDeliveryNote, isOrganization: true, organization: companyData, customerOrganization: companies.find(comp => comp.id === findDeliveryNote?.customerOrganizationId || comp.id === findDeliveryNote?.customerOrganization?.id) });
            setDocument(findDeliveryNote.base64);
        } else {
            let tempTask = allTasksUnformated.find(task => task.id === id || task.id == taskId);
            let tempProject = projects ? projects.find(project => project.id === projectId) : defaultProjectDTO;
            let newPosition = { title: tempTask?.title, description: tempTask?.description, quantity: 1, taskId: tempTask?.id, deliveryNoteId: deliveryNoteDTO.id, unit: 1 }
            setDeliveryNoteDTO({
                id: id,
                organization: companyData,
                title: allTasksUnformated.find(t => t.id === taskId)?.title,
                date: new Date(),
                isOrganization: true,
                customerOrganization: companies.find(comp => comp.id === tempTask?.customerOrganizationId),
                customerOrganizationId: companies.find(comp => comp.id === tempTask?.customerOrganizationId)?.id,
                textBefore: "Sehr geehrte Damen und Herren,\n\nvielen Dank für Ihre Bestellung und das damit verbundene Vertrauen!\nWir liefern Ihnen wie vereinbart folgende Waren:",
                projectId: tempProject?.id ?? undefined,
                tasks: allTasksUnformated.find(t => t.id === taskId) ? [allTasksUnformated.find(t => t.id === taskId)!] : [],
                textAfter: "Die gelieferte Ware bleibt bis zur vollständigen Bezahlung unser Eigentum." +
                    `\n\nMit freundlichen Grüßen\nIhr Team von ${companyData.name}`,
                positions: []

            })

            handleSaveSignature((deliveryNoteId: number) => {
                /*if (addDeliveryNotePosition) {
                    addDeliveryNotePosition({ ...newPosition, deliveryNoteId: deliveryNoteId })
                        .then((res: any) => {
                            let promises: Promise<any>[] = []; // Array to store promises

                            if (tempTask?.products && tempTask.products.length > 0) {
                                tempTask.products.forEach((product) => {
                                    let productToPosition = { title: product?.title, description: product?.description, quantity: product.count, productId: product?.id, deliveryNoteId: deliveryNoteId, unit: 1 }
                                    promises.push(addDeliveryNotePosition(productToPosition)); // Push promises into the array
                                })
                            }

                            // Wait for all promises to resolve
                            Promise.all(promises)
                                .then((responses: any[]) => {
                                    // Once all promises have resolved, update the delivery note positions and generate PDF
                                    setDeliveryNoteDTO((old: any) => ({ ...old, positions: [res, ...responses] }));
                                    generatePdf({ ...deliveryNoteDTO, id: deliveryNoteId, positions: [res, ...responses] });
                                })
                                .catch((error) => {
                                    console.error("Error occurred while adding product positions:", error);
                                });
                        })
                        .catch((error: any) => {
                            console.error("Error occurred while adding delivery note position:", error);
                        });
                }*/
            });
        }

        const customerOrganizations = companies.map((company) => {

            if (company.name && company.id) return {
                id: getCustomerListId(),
                customerId: company.id,
                name: company.name,
                type: CustomerOptionTypes.Organization
            }
        })

        const customerPersons = persons.map((person) => {
            if (person.id && person.completeName) return {
                id: getCustomerListId(),
                customerId: person.id,
                name: person.completeName,
                type: CustomerOptionTypes.Person
            }
        })

        let mergedCustomers: ICustomerOption[] = [];
        mergedCustomers = [...customerOrganizations as ICustomerOption[], ...customerPersons as ICustomerOption[]];
        mergedCustomers = mergedCustomers.sort((a, b) => a!.name.localeCompare(b!.name));

        setCustomerOptions([...mergedCustomers]);
    }, [id, companyData, companies, persons, taskId])

    const [debouncedTimer, setDebouncedTimer] = useState<any>();
    function debounce(func: any, delay: number) {
        return function () {
            const context = null;
            const args = arguments;
            clearTimeout(debouncedTimer);
            setDebouncedTimer(setTimeout(() => {
                func.apply(context, args);
            }, delay));
        };
    }

    const [newPosition, setNewPosition] = useState({ title: "", quantity: 1, unit: 0 });

    const setSerialNumbersOfPosition = (index: number, serialNumbers: IDeliveryNotePositionSerialNumber[]) => {
        let tempPosition: IDeliveryNotePositionDTO = { ...deliveryNoteDTO.positions![index], deliveryNotePositionSerialNumbers: serialNumbers }
        if (updateDeliveryNotePosition) {
            updateDeliveryNotePosition(tempPosition)
                .then(() => {
                    generatePdf()
                });
        }
    }

    useEffect(() => {
        console.log("Positions", deliveryNoteDTO.positions)
    }, [deliveryNoteDTO.positions])

    return (
        <Card
            title={!exists ? "Lieferschein erstellen" : "Lieferschein bearbeiten"}
            cta
            ctaTitle={!exists ? "Lieferschein erstellen" : "Lieferschein speichern"}
            ctaFunction={handleSaveSignature}
            style={{ width: "100%", height: "100%" }}
        >
            <div className='flex gap-2'>
                <FormRow
                    onChange={(val) => { setDeliveryNoteDTO((old: any) => ({ ...old, date: val })) }}
                    type='date'
                    value={deliveryNoteDTO.date}
                    label='Datum'
                    dateOptions={{
                        disablePast: false,
                        type: "Date"
                    }}
                />
                <FormRow
                    onChange={(val) => { setDeliveryNoteDTO((old: IDeliveryNoteDTO) => ({ ...old, number: parseInt(val) })) }}
                    type='number'
                    value={deliveryNoteDTO.number}
                    label='Lieferschein-Nr.'
                />

            </div>

            <FormRow
                onChange={(val) => setDeliveryNoteDTO((old: any) => ({ ...old, ...getNewDeliveryNoteProps(val) }))}
                value={deliveryNoteDTO.isOrganization ? customerOptions.find(option => option.customerId === deliveryNoteDTO.customerOrganization?.id)?.id : customerOptions.find(option => option.customerId === deliveryNoteDTO.customerPerson?.id)?.id}
                type='select'
                label='Kunde'
                selectOptions={{
                    options: customerOptions,
                    titleKey: "name",
                    valueKey: "id",
                    multiple: false
                }}
            />
            <FormRow
                onChange={(val) => setDeliveryNoteDTO((old: any) => ({ ...old, projectId: val }))}
                value={deliveryNoteDTO.projectId}
                type='select'
                label='Projekt'
                selectOptions={{
                    options: projects ?? [],
                    titleKey: "title",
                    valueKey: "id",
                    multiple: false
                }}
            />
            <div className='form-row-label' style={{ marginTop: "0.5em" }}>
                <span className='label'>Aufgaben</span>
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "flex-start", marginBottom: "1em", gap: "10px" }}>
                <FormRow
                    label=''
                    value={newTask}
                    onChange={(val) => { setNewTask(val) }}
                    type='select'
                    selectOptions={{
                        options: selectableTasks,
                        titleKey: "title",
                        valueKey: "id",
                        multiple: false
                    }}
                />
                <CTAButton
                    style={{ width: "50%" }}
                    onClickHandler={() => {
                        let positionExists = deliveryNoteDTO.positions?.find((t: IDeliveryNotePosition) => t.taskId === newTask)
                        if (!positionExists) {
                            if (addDeliveryNotePosition) {
                                let position = allTasksUnformated.find(t => t.id === newTask)
                                let newPosition = { title: position?.title, description: position?.description, quantity: 1, taskId: position?.id, deliveryNoteId: deliveryNoteDTO.id, unit: 1 }
                                addDeliveryNotePosition(newPosition)
                                    .then((res: any) => {
                                        let promises: Promise<any>[] = []; // Array to store promises

                                        if (position?.products && position.products.length > 0) {
                                            position.products.forEach((product) => {
                                                let productToPosition: IDeliveryNotePosition = { title: product?.title, description: product?.description, quantity: product.count, productId: product?.id, deliveryNoteId: deliveryNoteDTO.id, unit: 1 }
                                                promises.push(addDeliveryNotePosition(productToPosition)); // Push promises into the array
                                            })
                                        }

                                        // Wait for all promises to resolve
                                        Promise.all(promises)
                                            .then((responses: any[]) => {
                                                // Once all promises have resolved, update the delivery note positions and generate PDF
                                                setDeliveryNoteDTO((old: any) => ({ ...old, positions: [...old.positions, res, ...responses] }));
                                                generatePdf({ ...deliveryNoteDTO, positions: [...deliveryNoteDTO.positions!, res, ...responses] });
                                            })
                                            .catch((error) => {
                                                console.error("Error occurred while adding product positions:", error);
                                            });
                                    })
                                    .catch((error: any) => {
                                        console.error("Error occurred while adding delivery note position:", error);
                                    });
                            }
                        }
                    }}
                    title={"Aufgabe hinzufügen"}
                />
            </div>

            <div className='form-row-label' style={{ marginBottom: "0em" }}>
                <span className='label'>Produkte</span>
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "flex-start", marginBottom: "1em", gap: "8px" }}>
                <FormRow
                    label=''
                    value={newProduct}
                    onChange={(val) => { setNewProduct(val) }}
                    type='select'
                    selectOptions={{
                        options: products,
                        titleKey: "title",
                        valueKey: "id",
                        multiple: false
                    }}
                />
                <CTAButton
                    style={{ width: "40%" }}
                    onClickHandler={() => {
                        let positionExists = deliveryNoteDTO.positions?.find((t: IDeliveryNotePosition) => t.productId === newProduct)
                        if (positionExists) {
                            if (updateDeliveryNotePosition) {
                                updateDeliveryNotePosition({ ...positionExists, quantity: positionExists.quantity! + 1 })
                                    .then(() => {
                                        generatePdf({
                                            ...deliveryNoteDTO, positions: deliveryNoteDTO.positions!.map((position: IDeliveryNotePosition) => {
                                                if (position.id === positionExists!.id) {
                                                    return { ...position, quantity: position.quantity! + 1 }
                                                }
                                                return position;
                                            })
                                        });
                                    })
                                setDeliveryNoteDTO((old: any) => ({
                                    ...old, positions: old.positions.map((position: IDeliveryNotePosition) => {
                                        if (position.id === positionExists!.id) {
                                            return { ...position, quantity: position.quantity! + 1 }
                                        }
                                        return position;
                                    })
                                }))
                            }
                        } else {
                            if (addDeliveryNotePosition) {
                                let position = products.find(t => t.id === newProduct)
                                let newPosition = { title: position?.title, description: position?.description, quantity: 1, productId: position?.id, deliveryNoteId: deliveryNoteDTO.id, unit: 0 }
                                console.log("add new position", newPosition)
                                addDeliveryNotePosition(newPosition)
                                    .then((res: any) => {
                                        setDeliveryNoteDTO((old: any) => ({ ...old, positions: [...old.positions, res] }))
                                        generatePdf({ ...deliveryNoteDTO, positions: [...deliveryNoteDTO.positions!, res] });
                                    })
                            }
                        }
                    }}
                    title={"Produkt hinzufügen"}
                />
                <ProductScanner setScannedProductsInParent={setScannedProducts} />
            </div>

            <div style={{ width: "100%", display: "flex", gap: "0.25em" }}>
                <div style={{ width: "3%" }}></div>
                <div style={{ width: "57%" }}>Position</div>
                <div style={{ width: "10%" }}>Menge</div>
                <div style={{ width: "10%" }}>Einheit</div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "0.25em" }}>
                {deliveryNoteDTO.positions?.map((element: IDeliveryNotePosition, index: number) => {
                    return <>
                        <div style={{ width: "100%", display: "flex", alignItems: "center", gap: "0.25em" }}>
                            <div style={{ width: "3%", textAlign: "center" }}><p style={{ fontSize: "18px", }}>{index + 1}.</p></div>
                            <div style={{ width: "57%" }}><FormRow label='' onChange={(val) => {
                                if (updateDeliveryNotePosition) {
                                    let temp = {
                                        ...deliveryNoteDTO, positions: deliveryNoteDTO.positions!.map((position: IDeliveryNotePosition) => {
                                            if (position.id === element.id) {
                                                return { ...position, title: val }
                                            }
                                            return position
                                        })
                                    }
                                    let exec = debounce(() => {
                                        updateDeliveryNotePosition({ ...element, title: val })
                                            .then(() => {
                                                generatePdf(temp);
                                            })
                                    }, 2000)
                                    exec();
                                    setDeliveryNoteDTO(temp)
                                }

                            }} type='text' value={element.title} /></div>
                            <div style={{ width: "10%" }}><FormRow label='' onChange={(val) => {

                                if (updateDeliveryNotePosition) {
                                    let temp = {
                                        ...deliveryNoteDTO, positions: deliveryNoteDTO.positions!.map((position: IDeliveryNotePosition) => {
                                            if (position.id === element.id) {
                                                return { ...position, quantity: val }
                                            }
                                            return position
                                        })
                                    }
                                    let exec = debounce(() => {
                                        updateDeliveryNotePosition({ ...element, quantity: val })
                                            .then(() => {
                                                generatePdf(temp);
                                            })
                                    }, 2000)
                                    exec();
                                    setDeliveryNoteDTO(temp)
                                }

                            }} type='number' step='0.1' value={element.quantity} /></div>
                            <div style={{ width: "10%" }}><FormRow type='select' value={element.unit} onChange={(val) => {
                                if (updateDeliveryNotePosition) {
                                    let test = debounce(() => {
                                        updateDeliveryNotePosition({ ...element, unit: val })
                                            .then(() => {
                                                generatePdf({
                                                    ...deliveryNoteDTO, positions: deliveryNoteDTO.positions!.map((position: IDeliveryNotePosition) => {
                                                        if (position.id === element!.id) {
                                                            return { ...position, unit: val }
                                                        }
                                                        return position;
                                                    })
                                                });
                                            })
                                    }, 0)
                                    test();
                                    setDeliveryNoteDTO((old: any) => ({
                                        ...old, positions: old.positions.map((position: IDeliveryNotePosition) => {
                                            if (position.id === element!.id) {
                                                return { ...position, unit: val }
                                            }
                                            return position;
                                        })
                                    }))
                                }
                            }
                            } selectOptions={{ options: units, titleKey: "title", valueKey: "id", multiple: false }} /></div>
                            <div style={{ width: "10%", textAlign: "center", justifyContent: "center" }}>
                                SN:<SerialNumberScanner deliveryPositionId={element.id!} updateParentSerialNumbers={(serialNumbers: IDeliveryNotePositionSerialNumber[]) => { setSerialNumbersOfPosition(index, serialNumbers) }} />
                            </div>
                            <div style={{ width: "10%" }}>
                                <CTAButton
                                    color='error'
                                    variant='contained'
                                    onClickHandler={() => {
                                        if (deleteDeliveryNotePosition) {
                                            deleteDeliveryNotePosition(element)
                                                .then(() => {
                                                    generatePdf({ ...deliveryNoteDTO, positions: deliveryNoteDTO.positions!.filter((t: IDeliveryNotePosition) => t.id !== element.id) });
                                                })
                                            setDeliveryNoteDTO((old: any) => ({ ...old, positions: old.positions.filter((t: IDeliveryNotePosition) => t.id !== element.id) }))
                                        }
                                    }}
                                    title={<Delete />}
                                />
                            </div>
                        </div>
                    </>
                })}
                <>
                    <div style={{ width: "100%", display: "flex", alignItems: "center", gap: "0.25em" }}>
                        <div style={{ width: "3%", textAlign: "center" }}><p style={{ fontSize: "18px", }}>{(deliveryNoteDTO.positions?.length ?? 0) + 1}.</p></div>
                        <div style={{ width: "57%" }}
                        >
                            <FormRow label='' onChange={(val) => {
                                if (addDeliveryNotePosition) {
                                    setNewPosition((old: any) => ({ ...old, title: val }))

                                    let exec = debounce(async () => {
                                        let temp = {
                                            ...deliveryNoteDTO, positions: [...deliveryNoteDTO.positions!, { ...newPosition, title: val }]
                                        }
                                        setDeliveryNoteDTO(temp)
                                        setNewPosition({ title: "", quantity: 1, unit: 0 })

                                        const result = await addDeliveryNotePosition({ title: val, description: "", quantity: newPosition.quantity, deliveryNoteId: deliveryNoteDTO.id, unit: newPosition.unit })
                                        temp = { ...deliveryNoteDTO, positions: [...deliveryNoteDTO.positions!, result] }
                                        setDeliveryNoteDTO(temp);
                                        generatePdf(temp);
                                    }, 2000)
                                    exec();

                                }

                            }} type='text' value={newPosition.title} placeholder='Neue Position hinzufügen' /></div>
                        <div style={{ width: "10%" }}><FormRow label='' onChange={(val) => {

                            setNewPosition((old: any) => ({ ...old, quantity: val }))

                        }} type='number' step='0.1' value={newPosition.quantity} /></div>
                        <div style={{ width: "10%" }}><FormRow type='select' value={newPosition.unit} onChange={(val) => {
                            setNewPosition((old: any) => ({ ...old, unit: val }))
                        }} selectOptions={{ options: units, titleKey: "title", valueKey: "id", multiple: false }} /></div>
                        <div style={{ width: "10%" }}></div>
                        <div style={{ width: "10%" }}>
                            <CTAButton
                                color='error'
                                variant='contained'
                                disabled
                                onClickHandler={() => {
                                    /*if (deleteDeliveryNotePosition) {
                                        deleteDeliveryNotePosition(newPosition)
                                            .then(() => {
                                                generatePdf({ ...deliveryNoteDTO, positions: deliveryNoteDTO.positions!.filter((t: IDeliveryNotePosition) => t.id !== newPosition.id) });
                                            })
                                        setDeliveryNoteDTO((old: any) => ({ ...old, positions: old.positions.filter((t: IDeliveryNotePosition) => t.id !== newPosition.id) }))
                                    }*/
                                }}
                                title={<Delete />}
                            />
                        </div>
                    </div>
                </>
            </div>
            {/*<FormRow
                onChange={(val) => { setDeliveryNoteDTO((old: any) => ({ ...old, textAfter: val })) }}
                type='multiline'
                value={deliveryNoteDTO.textAfter}
                label='Fußtext'
            />*/}
            <div className='form-row-label'>
                <span className='label'>Unterschrift</span>
                <div style={{ border: "1px solid #000000", backgroundColor: "var(--light)", borderRadius: "1em", maxWidth: "calc(100%)", width: "100%", overflow: "hidden" }}>
                    <SignatureCanvas penColor='black'
                        ref={signatureRef}
                        canvasProps={{ style: { height: "100%", width: "100%" } }} />
                </div>
            </div>
        </Card>
    )

    function getNewDeliveryNoteProps(customerOptionId: number): IDeliveryNoteDTO {
        /*         onChange = {(val) => setDeliveryNoteDTO((old: any) => ({
                    ...old, organization: companyData,
                    isOrganization: customerOptions.find(customerOption => customerOption.id === val)?.type === CustomerOptionTypes.Organization,
                    customerOrganization: customerOptions.find(customerOption => customerOption.id === val)?.type === CustomerOptionTypes.Organization ? companies.find(company => company.id === customerOptions.find(customerOption => customerOption.id === val)?.customerId) : undefined,
                    customerPerson: customerOptions.find(customerOption => customerOption.id === val)?.type === CustomerOptionTypes.Person ? persons.find(person => person.id === customerOptions.find(customerOption => customerOption.id === val)?.customerId) : undefined
                }))
            }
         */

        const selectedCustomer = customerOptions.find(option => option.id === customerOptionId)
        const isOrganization = selectedCustomer?.type === CustomerOptionTypes.Organization;
        const customerOrganization = isOrganization ? companies.find(company => company.id === selectedCustomer?.customerId) : undefined;
        const customerPerson = !isOrganization ? persons.find(person => person.id === selectedCustomer?.customerId) : undefined;
        const customerOrganizationId = customerOrganization?.id;
        const customerPersonId = customerPerson?.id;

        const deliveryNoteDTO: IDeliveryNoteDTO = {
            organization: companyData,
            isOrganization: isOrganization,
            customerOrganization: customerOrganization,
            customerOrganizationId: customerOrganizationId,
            customerPerson: customerPerson,
            customerPersonId: customerPersonId
        }

        return deliveryNoteDTO;
    }
}

export default DeliveryNoteFormCard