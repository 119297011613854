import { Card, CardContent } from "../../Components/UI/CardNew";
import { useUser } from "../../Contexts/UserContext/UserContext";

export default function RegisterNewOrganization() {
  const { user } = useUser();
  return (
    <Card className="lg:w-[60%] h-full lg:h-[80%] ml-auto mr-auto pb-2 justify-evenly">
      <span className="text-center font-semibold text-3xl">
        Registrierung Ihrer Organisation
      </span>
      <CardContent className="p-2 flex flex-row flex-wrap justify-center h-auto">
        {process.env!.NODE_ENV === "production" && (
          <>
            {/*@ts-ignore*/}
            <stripe-pricing-table
              pricing-table-id="prctbl_1MzOzbCJ5DwX1VoadPd4vfzl"
              publishable-key="pk_live_51MyZMcCJ5DwX1VoaUNKnlxO3beokaBU9J0IhhiNJwvtD03ngDLRLn9NL6tXTec6vexJHVPxaGTPq5Gib422vLblk00fD0B5t0L"
              client-reference-id={user?.userId}
            />
          </>
        )}
        {process.env!.NODE_ENV === "development" && (
          <>
            {/*@ts-ignore*/}
            <stripe-pricing-table
              pricing-table-id="prctbl_1MyidFCJ5DwX1VoasZtbkn5C"
              publishable-key="pk_test_51MyZMcCJ5DwX1Voab0vZx8ObKkqbR2cgn0aIyGckXOafnkgRZjHUbwXk2v5ApUQk6CUFoJJs91HsjlpfFq4kg5QY00LZ4AlTSl"
              client-reference-id={user?.userId}
            />
            {/*@ts-ignore*/}
            <stripe-pricing-table
              pricing-table-id="prctbl_1MyidFCJ5DwX1VoasZtbkn5C"
              publishable-key="pk_test_51MyZMcCJ5DwX1Voab0vZx8ObKkqbR2cgn0aIyGckXOafnkgRZjHUbwXk2v5ApUQk6CUFoJJs91HsjlpfFq4kg5QY00LZ4AlTSl"
              client-reference-id={user?.userId}
            />
          </>
        )}
      </CardContent>
      <span className="text-center text-sm">
        Wählen Sie eine Abrechnungsart und klicken Sie auf "Abonnieren"
      </span>
    </Card>
  );
}
