import { useState } from 'react'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { IPause, useTimeTracking } from '../../../Contexts/TimeTrackingContext/TimeTrackingProvider'
import useAuthConnection from '../../../Hooks/useAuthConnection'
import { useSnackBar } from '../../../Hooks/useSnackBar'
import { IServiceDTO } from '../../../Interfaces/Service'
import Modal from '../../Dialog/Dialog'
import Pagination from '../../Pagination/Pagination'
import { dialogWithProps } from '../EditCreateCompanyDialog/EditCreateCompanyDialog'
import WorkTimeRow from './WorkTimeRow'



function WorkTimeOverviewDialog({ open, onClose, object, mode }: dialogWithProps<IServiceDTO>) {
  const { changeVisibility } = useModals()
  const { enqueueSnackbar } = useSnackBar()

  const { worktimes, pauses } = useTimeTracking();

  const [page, setPage] = useState<number>(0);

  //Zum Zurücksetzen Zeit beim Öffnen/Schließen des Modals
  const defaultTime = {
    start: new Date(),
    end: new Date()
  }

  const [time, setTime] = useState<{ start: Date, end: Date }>({
    ...defaultTime
  })

  //*Ladestate beim Abschicken an die API
  const [loading, setLoading] = useState(false)

  const connection = useAuthConnection();

  const getBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)

      /*const formData = new FormData();
      formData.append("file", src)

      connection.post("/user/profilepicture", formData)
        .then((res: AxiosResponse) => {
          fetchStaff!();
          let base64 = "";
          getBase64(src, (res: any) => { base64 = res; });
          setProfilePicture!(base64)
        })*/

      handleClose()
    } catch (error) {
      enqueueSnackbar(`Fehler beim Nachtragen.`, { variant: "error" })
      console.error(error)
    } finally {
      setLoading(false)
    }

  }

  const handleClose = async () => {
    setLoading(false)
    if (onClose) {
      onClose()
    }
    setTime(defaultTime)
    changeVisibility!("worktimeOverview", false)
  }

  return (
    <Modal
      open={open}
      title={"Arbeitszeitübersicht"}
      onClose={handleClose}
    >
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start", minHeight: "55vh" }}>
        {worktimes && worktimes?.length > 0 && worktimes!.map((worktime, index) => {
          if (index >= (page) * 5 && index < (page + 1) * 5) {
            return (
              <WorkTimeRow workTime={worktime} n={index} id={worktime} key={index}
                pauses={pauses?.filter((pause: IPause) => {
                  if (pause.workTimeId === worktime.id) {
                    return true;
                  }
                  return false;
                })}
              />)
          }
        })}
      </div>
      <Pagination items={worktimes ?? []} itemsPerPage={5} page={page} setPage={setPage} />
    </Modal >
  )
}

export default WorkTimeOverviewDialog