import { useEffect, useState } from "react";
import { ITravel } from "../../Interfaces/Travel";
import { useGetAllTravelsQuery } from "../../redux/apiSlices/Travel";
import FormRow from "../Forms/FormUtils/FormRow";

export type TravelSelectProps = {
  onSelectionChange: (selectedTravels: ITravel[]) => void;
  initialSelectedTravels: ITravel[];
};
export default function TravelSelect({
  onSelectionChange,
  initialSelectedTravels,
}: TravelSelectProps) {
  const { data: travels, isLoading } = useGetAllTravelsQuery();

  const [selectedTravelIds, setSelectedTravelIds] = useState<number[]>([]);

  useEffect(() => {
    if (selectedTravelIds.length > 0) {
      let tempSelectedTravels: ITravel[] = [];
      selectedTravelIds.forEach((id) => {
        const travel = travels?.find((t) => t.id === id);

        if (travel) tempSelectedTravels.push(travel);
      });
      onSelectionChange(tempSelectedTravels);
    }
  }, [selectedTravelIds]);

  useEffect(() => {
    if (
      initialSelectedTravels &&
      initialSelectedTravels.length > 0 &&
      selectedTravelIds.length === 0
    ) {
      setSelectedTravelIds(initialSelectedTravels.map((t) => t.id));
    }
  }, [initialSelectedTravels]);

  return (
    <>
      {!isLoading && (
        <FormRow
          label="Fahrt zuordnen"
          type="select"
          value={selectedTravelIds}
          onChange={(val) => {
            onSelectionChange(val);
            setSelectedTravelIds(val);
          }}
          selectOptions={{
            options: travels ?? [],
            titleKey: "title",
            valueKey: "id",
            multiple: true,
          }}
        />
      )}
    </>
  );
}
