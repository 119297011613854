import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ITask, ITaskDTO } from '../../Interfaces/Task';


//Icons
import { CameraAlt, Done, MoreVertRounded, PlayArrowRounded, StopRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useModals } from '../../Contexts/DialogContext/DialogProvider';
import { useTasks } from '../../Contexts/TaskContext/TaskContext';
import { useUser } from '../../Contexts/UserContext/UserContext';
import { taskFormModes } from '../../Pages/Aufgaben/Aufgaben';
import { useHandleStartServiceToTask, useHandleStopServiceToTask } from '../../redux/requestHandlers/Service';
import IconTooltipButton from '../Buttons/IconTooltipButton/IconTooltipButton';
import CapturePicturesModal from '../Camera/CapturePicturesModal';
import ContextMenu from '../Menu/ContextMenu';
import { ITaskSelectMode } from '../TaskCard/TaskCard';

function Task({ task, mode, setClickedTask, setFormMode, openModalOnTaskClick, index, organizationIndex }: { task: ITask, mode: ITaskSelectMode, setClickedTask?: Dispatch<SetStateAction<ITaskDTO>>, setFormMode?: Dispatch<SetStateAction<taskFormModes>>, openModalOnTaskClick?: boolean, index: number, organizationIndex: number }) {
    const { changeVisibility } = useModals()
    const { handleStartServiceToTask, isLoading: isStartServiceToTaskLoading } = useHandleStartServiceToTask();
    const { handleStopServiceToTask, isLoading: isStopServiceToTaskLoading } = useHandleStopServiceToTask();
    const { modifySelectedTask, selectedTasks, deleteTask, finishTask } = useTasks()
    const { user } = useUser();

    const [picturesModalOpen, setPicturesModalOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [isBefore, setIsBefore] = useState<boolean>(true);

    useEffect(() => {
        if (user?.workingOnTask === task.id) {
            setIsBefore(false)
        } else {
            setIsBefore(true)
        }
    }, [user])

    //! Bugfix
    const handleChange = (newChecked?: boolean) => {
        try {
            if (newChecked == true) {
                modifySelectedTask!(task, "add")
                //removeTaskToDeliver!(task.id)
            } else {
                modifySelectedTask!(task, "remove")
                //addTaskToDeliver!(task)
            }
        } catch (error) {
            changeVisibility!("error", true, { error: { message: "Lieferscheine können nur für ein Unternehmen auf einmal erstellt werden.", title: "Warnung" } })
        }
    }

    const handleOptionsClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }

    const handleClick = () => {
        if (openModalOnTaskClick) {
            changeVisibility!("createTask", true, { props: { object: task, mode: "edit" } });
        }
        else {
            if (setFormMode && setClickedTask) {
                setClickedTask(task);
                setFormMode("editTask");
            }
        }
    }

    const navigate = useNavigate();

    const [object, setObject] = useState<any>(task);

    useEffect(() => { setObject(task) }, [task])

    const startService = () => {
        handleStartServiceToTask(task);
    }

    const takePictures = () => {
        setObject(task); setPicturesModalOpen(true);
    }

    const stopService = () => {
        handleStopServiceToTask(task);
    }


    return (
        <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "2px", paddingBottom: "2px", backgroundColor: (organizationIndex ? (index - organizationIndex) : index) % 2 === 0 ? "var(--ultra-light)" : "transparent" }}>
            <div className='flex centered-aligned pointer' style={{ width: "73%", paddingLeft: "2.5%" }} onClick={() => handleClick()}>
                {/* ToDo: Fix multiselect <Checkbox
                    checked={selectedTasks.find((selectedTask) => selectedTask.id == task.id) !== undefined}
                    onChange={(e, checked) => handleChange(checked)}
                    style={{ color: "var(--brand)" }}
                />*/}
                <span style={{ width: "70%" }}>
                    {task.title}
                </span>
            </div>
            <div className='flex pointer' style={{ width: "25%", justifyContent: "flex-end" }} onClick={() => handleClick()}>
                {!(user?.workingOnTask) &&
                    <IconTooltipButton onClick={(e) => { e?.stopPropagation(); startService(); }} arrow title={'Leistung zu Aufgabe starten'} placement="right">
                        <PlayArrowRounded sx={{ color: 'var(--green)' }} fontSize='small' />
                    </IconTooltipButton>
                }
                {(user?.workingOnTask === task.id) &&
                    <IconTooltipButton onClick={(e) => { e?.stopPropagation(); stopService() }} arrow title='Arbeitszeit beenden' placement="right">
                        <StopRounded sx={{ color: 'var(--red-light)' }} fontSize='small' />
                    </IconTooltipButton>
                }
                {true && user?.takesPicturesForServices &&
                    <IconTooltipButton onClick={(e) => { e?.stopPropagation(); takePictures(); }} arrow title={'Leistung zu Aufgabe starten'} placement="right">
                        <CameraAlt sx={{ color: 'var(--text-light)' }} fontSize='small' />
                    </IconTooltipButton>
                }
                {true &&
                    <IconTooltipButton onClick={(e) => { e?.stopPropagation(); if ((user?.workingOnTask === task.id)) { stopService(); } if (finishTask) (finishTask(task)); }} arrow title={(user?.workingOnTask === task.id) ? 'Leistung abschliessen und Aufgabe fertigstellen' : 'Aufgabe abschliessen'} placement="right">
                        <Done sx={{ color: 'var(--green)' }} fontSize='small' />
                    </IconTooltipButton>
                }
                <IconTooltipButton
                    title='Optionen'
                    onClick={(e) => { e?.stopPropagation(); handleOptionsClick(e); }}
                >
                    <MoreVertRounded
                        style={{ fontSize: "16px" }}
                        color='inherit'
                    />
                </IconTooltipButton>
            </div>

            <ContextMenu
                anchorEl={anchorEl}
                onClose={(index) => setAnchorEl(null)}
                options={[
                    {
                        label: "Bearbeiten",
                        onClick: () => changeVisibility!("createTask", true, { props: { object: task, mode: "edit" } }),
                        selectable: false
                    },
                    {
                        label: "Fertigstellen",
                        onClick: () => changeVisibility!("finishTask", true, { props: { object: task, mode: "edit" } }),
                        selectable: false
                    },
                    {
                        label: "Leistung erstellen",
                        onClick: () => changeVisibility!("transformIntoService", true, { props: { object: [task] } }),
                        selectable: false
                    },
                    {
                        label: "Lieferschein erstellen",
                        onClick: () => { navigate(`/aufgaben/lieferschein/erstellen?taskId=${task.id}`) },
                        selectable: false
                    },
                    {
                        label: "Stornieren",
                        onClick: () => changeVisibility!("abortTask", true, { props: { object: task } }),
                        selectable: false
                    },
                    {
                        label: "Löschen",
                        onClick: () => changeVisibility!("yesNoDialog", true, { props: { object: { submitFunction: () => { deleteTask!(task) }, modalTitle: "Aufgabe löschen", modalText: "Möchten Sie diese Aufgabe wirklich löschen?", confirmButtonText: "Löschen" } } }),
                        selectable: false
                    },
                ]}
            />
            <CapturePicturesModal
                inputTask={task}
                open={picturesModalOpen}
                onClose={() => { setPicturesModalOpen(false) }}
                onSubmit={() => {
                    handleStartServiceToTask(task);
                }}
            />
        </div>
    )
}

export default Task